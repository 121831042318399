import React from 'react'
import { memo } from '~/ui/component'
import { Center, FlexProp, Label } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  status?:   number
  title:     React.ReactNode
  detail?:   React.ReactNode | null
  flex?:     FlexProp
  children?: React.ReactNode
}

const ErrorDisplay = memo('ErrorDisplay', (props: Props) => {

  const {status, title, detail, flex, children} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Center flex={flex} gap={layout.padding.l}>
        {renderStatus()}
        {renderLabels()}
        {children}
      </Center>
    )
  }

  function renderStatus() {
    if (status == null) { return null }

    return (
      <Center>
        <Label bold classNames={$.errorStatus}>
          {status}
        </Label>
      </Center>
    )
  }

  function renderLabels() {
    const align = status == null ? 'center' : 'left'

    return (
      <Center gap={layout.padding.inline.s}>
        {isReactText(title) ? <Label h3 align={align}>{title}</Label> : title}
        {isReactText(detail) ? <Label small dim truncate={false} align={align}>{detail}</Label> : detail}
      </Center>
    )
  }

  return render()

})

export default ErrorDisplay

const useStyles = createUseStyles({
  errorStatus: {
    fontSize: 40,
  },
})