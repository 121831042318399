/**
 * This file is generated by scripts/svg.ts. Please don't modify manually.
 */

const svgs = {
  "archive": require("./archive.svg"),
  "arrow-down": require("./arrow-down.svg"),
  "arrow-left": require("./arrow-left.svg"),
  "arrow-right": require("./arrow-right.svg"),
  "arrow-up": require("./arrow-up.svg"),
  "asterisk": require("./asterisk.svg"),
  "beacon": require("./beacon.svg"),
  "bell": require("./bell.svg"),
  "blank-page": require("./blank-page.svg"),
  "bold": require("./bold.svg"),
  "building": require("./building.svg"),
  "calendar": require("./calendar.svg"),
  "camera": require("./camera.svg"),
  "certificate": require("./certificate.svg"),
  "challenge": require("./challenge.svg"),
  "chart": require("./chart.svg"),
  "chat": require("./chat.svg"),
  "check": require("./check.svg"),
  "chevron-down": require("./chevron-down.svg"),
  "chevron-left": require("./chevron-left.svg"),
  "chevron-right": require("./chevron-right.svg"),
  "chevron-up": require("./chevron-up.svg"),
  "click": require("./click.svg"),
  "clock": require("./clock.svg"),
  "code": require("./code.svg"),
  "cog": require("./cog.svg"),
  "column-chart": require("./column-chart.svg"),
  "comment": require("./comment.svg"),
  "converse-script": require("./converse-script.svg"),
  "correct": require("./correct.svg"),
  "course": require("./course.svg"),
  "cross": require("./cross.svg"),
  "dashboard": require("./dashboard.svg"),
  "database": require("./database.svg"),
  "deactivate": require("./deactivate.svg"),
  "debug": require("./debug.svg"),
  "disk": require("./disk.svg"),
  "double-check": require("./double-check.svg"),
  "double-chevron-left": require("./double-chevron-left.svg"),
  "double-chevron-right": require("./double-chevron-right.svg"),
  "doubleclick": require("./doubleclick.svg"),
  "download": require("./download.svg"),
  "empty": require("./empty.svg"),
  "error-full": require("./error-full.svg"),
  "eye-off": require("./eye-off.svg"),
  "eye": require("./eye.svg"),
  "feed": require("./feed.svg"),
  "filter": require("./filter.svg"),
  "finish-flag": require("./finish-flag.svg"),
  "flash": require("./flash.svg"),
  "folder": require("./folder.svg"),
  "grid": require("./grid.svg"),
  "group": require("./group.svg"),
  "h1": require("./h1.svg"),
  "h2": require("./h2.svg"),
  "hamburger": require("./hamburger.svg"),
  "hand": require("./hand.svg"),
  "handshake": require("./handshake.svg"),
  "headset": require("./headset.svg"),
  "home": require("./home.svg"),
  "image": require("./image.svg"),
  "incorrect": require("./incorrect.svg"),
  "index-page": require("./index-page.svg"),
  "info": require("./info.svg"),
  "iphone": require("./iphone.svg"),
  "italic": require("./italic.svg"),
  "kebab": require("./kebab.svg"),
  "lang.en": require("./lang.en.svg"),
  "lang.nl": require("./lang.nl.svg"),
  "link": require("./link.svg"),
  "list": require("./list.svg"),
  "lock": require("./lock.svg"),
  "login": require("./login.svg"),
  "logo": require("./logo.svg"),
  "logout": require("./logout.svg"),
  "map-pin": require("./map-pin.svg"),
  "map": require("./map.svg"),
  "meeting": require("./meeting.svg"),
  "menu-page": require("./menu-page.svg"),
  "message.error": require("./message.error.svg"),
  "message.pending": require("./message.pending.svg"),
  "message.read": require("./message.read.svg"),
  "message.redacted": require("./message.redacted.svg"),
  "message.sent": require("./message.sent.svg"),
  "offline": require("./offline.svg"),
  "ordered-list": require("./ordered-list.svg"),
  "p": require("./p.svg"),
  "page": require("./page.svg"),
  "palette": require("./palette.svg"),
  "paperclip": require("./paperclip.svg"),
  "participant": require("./participant.svg"),
  "pencil": require("./pencil.svg"),
  "pin": require("./pin.svg"),
  "planner": require("./planner.svg"),
  "play": require("./play.svg"),
  "plus-circle": require("./plus-circle.svg"),
  "plus": require("./plus.svg"),
  "puzzle": require("./puzzle.svg"),
  "qrcode": require("./qrcode.svg"),
  "question": require("./question.svg"),
  "random-node": require("./random-node.svg"),
  "reload": require("./reload.svg"),
  "reply": require("./reply.svg"),
  "robot": require("./robot.svg"),
  "satellite": require("./satellite.svg"),
  "search": require("./search.svg"),
  "sort-ascending": require("./sort-ascending.svg"),
  "sort-descending": require("./sort-descending.svg"),
  "star-empty": require("./star-empty.svg"),
  "star-full": require("./star-full.svg"),
  "star-half": require("./star-half.svg"),
  "star-input-empty": require("./star-input-empty.svg"),
  "star-input-full": require("./star-input-full.svg"),
  "star-input-half": require("./star-input-half.svg"),
  "star": require("./star.svg"),
  "stopwatch": require("./stopwatch.svg"),
  "switch-off": require("./switch-off.svg"),
  "switch-on": require("./switch-on.svg"),
  "tag": require("./tag.svg"),
  "text": require("./text.svg"),
  "trainer": require("./trainer.svg"),
  "trash": require("./trash.svg"),
  "trophy": require("./trophy.svg"),
  "tumbleweed": require("./tumbleweed.svg"),
  "tv": require("./tv.svg"),
  "unlock": require("./unlock.svg"),
  "unordered-list": require("./unordered-list.svg"),
  "upload": require("./upload.svg"),
  "user": require("./user.svg"),
  "video": require("./video.svg"),
  "warning-full": require("./warning-full.svg"),
  "web": require("./web.svg"),
  "webcast": require("./webcast.svg"),
}

export default svgs