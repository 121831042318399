import React from 'react'
import { useTimer } from 'react-timer'
import { Editor, EditorState } from 'draft-js'
import { Media, SVGImage } from '~/models'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { observer } from '~/ui/component'
import { Scroller, Uploader, useMarkdownStyles, VBox, VBoxProps } from '~/ui/components'
import MarkdownField from '~/ui/components/fields/MarkdownField'
import { FieldChangeCallback, invokeFieldChangeCallback } from '~/ui/form'
import { useContinuousRef, useViewState } from '~/ui/hooks'
import { createUseStyles, fonts, layout, presets, shadows } from '~/ui/styling'
import DraftJSBackend from './backend/DraftJSBackend'
import MarkdownBackend from './backend/MarkdownBackend'
import renderBlock from './blocks'
import { RichTextFieldContext } from './RichTextFieldContext'
import RichTextFieldToolbar from './RichTextFieldToolbar'
import { EditorMode, RichTextFieldHandle, RichTextNodeType, RichTextScope } from './types'

export interface Props {
  value:     string | null
  onChange?: ((value: string) => any) | FieldChangeCallback<string>
  onCommit?: (value: string) => any

  placeholder?: string | null

  scope?: RichTextScope
  allowedNodes?: RichTextNodeType[] | '*'

  enabled?:       boolean
  invalid?:       boolean
  autoFocus?:     boolean
  selectOnFocus?: boolean
  commitOnBlur?:  boolean

  renderToolbarRight?: () => React.ReactNode
  renderHeader?:       () => React.ReactNode
  renderFooter?:       () => React.ReactNode

  flex?:            VBoxProps['flex']
  height?:          number
  borderTopRadius?: number
  classNames?:      React.ClassNamesProp
  bodyClassNames?:  React.ClassNamesProp
  scrollable?:      boolean
  showFocus?:       boolean
}

const RichTextField = observer('RichTextField', React.forwardRef((props: Props, ref: React.Ref<RichTextFieldHandle>) => {

  const {
    value,
    onChange,
    onCommit,
    placeholder,

    scope = 'block',
    allowedNodes = '*',

    enabled       = true,
    invalid       = false,
    autoFocus     = false,
    selectOnFocus = false,
    commitOnBlur  = false,

    flex,
    height,
    borderTopRadius = presets.fieldBorderRadius,
    scrollable,

    renderToolbarRight,
    renderHeader,
    renderFooter,
    showFocus = true,

    classNames,
    bodyClassNames,
  } = props

  const valueRef            = useContinuousRef(value)
  const editorRef           = React.useRef<Editor>(null)
  const markdownTextAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const uploaderRef         = React.useRef<Uploader>(null)

  // This prevents the DraftJS backend from being recreated on every render
  const allowedNodesRef = useContinuousRef(allowedNodes)

  //------
  // Backends

  const [editorMode, state_setEditorMode] = useViewState<EditorMode>('rich-text-field.mode', 'wysiwyg')

  const onChangeRef = useContinuousRef(onChange)
  const onCommitRef = useContinuousRef(onCommit)

  const onChangeForBackend = React.useCallback((value: string, commit: boolean) => {
    invokeFieldChangeCallback(onChangeRef.current, value, commit)
  }, [onChangeRef])

  const onCommitForBackend = React.useCallback((value: string) => {
    onCommitRef.current?.(value)
  }, [onCommitRef])

  const backend = React.useMemo(() => {
    if (editorMode === 'wysiwyg') {
      return new DraftJSBackend(valueRef.current ?? '', scope, onChangeForBackend, onCommitForBackend, allowedNodesRef.current)
    } else {
      return new MarkdownBackend(valueRef.current ?? '', markdownTextAreaRef, scope, onChangeForBackend, onCommitForBackend)
    }
  }, [editorMode, onChangeForBackend, onCommitForBackend, scope, valueRef, allowedNodesRef])

  // For some reason, MobX doesn't get into action unless we try to get this.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = backend instanceof DraftJSBackend ? backend.editorState : null

  React.useEffect(() => {
    backend.updateFromValue(value ?? '')
  }, [backend, value])

  const handleMarkdownChange = React.useCallback((value: string) => {
    if (!(backend instanceof MarkdownBackend)) { return }
    backend.handleChange(value)
  }, [backend])

  const handleMarkdownSelectionChange = React.useCallback(() => {
    if (!(backend instanceof MarkdownBackend)) { return }
    backend.handleSelectionChange()
  }, [backend])

  const handleDraftJSChange = React.useCallback((state: EditorState) => {
    if (!(backend instanceof DraftJSBackend)) { return }
    backend.handleChange(state)
  }, [backend])

  //------
  // Focus & select

  const selectAll = React.useCallback(() => backend.selectAll(), [backend])

  const focus = React.useCallback(() => {
    editorRef.current?.focus()

    if (selectOnFocus) {
      selectAll()
    }
  }, [selectAll, selectOnFocus])

  const blur = React.useCallback(() => {
    editorRef.current?.blur()
  }, [])

  const preventBlurRef = React.useRef<boolean>(false)
  const timer = useTimer()

  const mouseDown = React.useCallback(() => {
    preventBlurRef.current = true
    timer.setTimeout(() => {
      preventBlurRef.current = false
    }, 0);
  }, [timer])

  React.useEffect(() => {
    if (autoFocus) {
      focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlur = React.useCallback((event: React.FocusEvent) => {
    if (preventBlurRef.current) {
      event.preventDefault()
      return
    }

    if (commitOnBlur) {
      onCommit?.(value ?? '')
    }
  }, [commitOnBlur, onCommit, value])

  React.useImperativeHandle(ref, () => ({
    focus,
    blur,
    selectAll,
  }))

  //------
  // Media & links
  const insertMedia = React.useCallback((media: Media | SVGImage | null) => {
    if (media instanceof Media) {
      backend.insertMedia(media)
    }
  }, [backend])

  const acceptMedia = scope === 'block' && (allowedNodes === '*' || allowedNodes.includes('image'))

  const openMediaPicker = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  //------
  // Mode

  const setEditorMode = React.useCallback((mode: EditorMode) => {
    state_setEditorMode(mode)
    if (mode === 'wysiwyg') {
      timer.setTimeout(() => {
        focus()
      }, 0)
    }
  }, [focus, state_setEditorMode, timer])

  //------
  // Rendering

  const $ = useStyles({height})
  const markdown$ = useMarkdownStyles()

  const borderRadiusStyle: React.CSSProperties = {
    borderTopLeftRadius:  borderTopRadius,
    borderTopRightRadius: borderTopRadius,
  }

  function render() {
    return (
      <VBox flex={flex} classNames={[$.richTextField, {invalid, showFocus}, classNames]} style={borderRadiusStyle}>
        {acceptMedia ? (
          renderDropzone()
        ) : (
          renderEditor()
        )}
      </VBox>
    )
  }

  function renderDropzone() {
    if (!acceptMedia) { return null }

    return (
      <MediaUploader
        uploaderRef={uploaderRef}
        accept={['image/jpeg', 'image/png', 'image/gif']}
        renderContent={renderDropzoneContent}
        onUploadComplete={insertMedia}
        noKeyboard
        noClick
      />
    )
  }

  function renderDropzoneContent(state: MediaUploaderState) {
    return (
      <VBox flex={flex} classNames={$.dropzoneContent}>
        {renderEditor()}
        {state.isDragActive && state.renderDropHint()}
        {state.renderUploading()}
      </VBox>
    )
  }

  function renderEditor() {
    return (
      <RichTextFieldContext.Provider value={backend}>
        <VBox flex={flex} onMouseDown={mouseDown} className={$.container}>
          <VBox classNames={$.toolbar} style={borderRadiusStyle}>
            {renderToolbar()}
          </VBox>
          {renderHeader?.()}
          <VBox flex={flex} classNames={[$.body, markdown$.markdown, bodyClassNames]}>
            {scrollable ? (
              <Scroller flex={flex}>
                {renderEditorBody()}
              </Scroller>
            ) : (
              renderEditorBody()
            )}
          </VBox>
          {renderFooter?.()}
        </VBox>
      </RichTextFieldContext.Provider>
    )
  }

  function renderToolbar() {
    const headingButtons = scope === 'block' && (allowedNodes === '*' || allowedNodes.includes('heading'))
    const listButtons    = scope === 'block' && (allowedNodes === '*' || allowedNodes.includes('list'))

    return (
      <RichTextFieldToolbar
        editorMode={editorMode}
        setEditorMode={setEditorMode}
        renderRight={renderToolbarRight}
        scope={scope}
        requestInsertMedia={acceptMedia ? openMediaPicker : undefined}
        headingButtons={headingButtons}
        listButtons={listButtons}
      />
    )
  }

  function renderEditorBody() {
    if (backend instanceof DraftJSBackend) {
      return (
        <Editor
          ref={editorRef}
          editorState={backend.editorState}
          keyBindingFn={backend.keyBindingFn}
          handleKeyCommand={backend.keyCommandHandler}
          blockRendererFn={renderBlock}
          onChange={handleDraftJSChange}
          onBlur={handleBlur}
          readOnly={!enabled}
          placeholder={placeholder ?? undefined}
        />
      )
    } else {
      return(
        <MarkdownField
          classNames={$.markdownEditor}
          ref={markdownTextAreaRef}
          value={backend.value}
          onChange={handleMarkdownChange}
          onCommit={onCommit}
          inputAttributes={{
            onSelect: handleMarkdownSelectionChange,
          }}
          enabled={enabled}
          autoFocus
        />
      )
    }
  }

  return render()

}))

export default RichTextField

export const minHeight = 80

const useStyles = createUseStyles(theme => ({
  richTextField: {
    ...presets.field(theme),
    ...fonts.responsiveFontStyle(theme.fonts.body),

    background: 'red',

    fontWeight: 400,
    cursor:     'text',
    padding:    0,
    overflow:   'hidden',

    '&.invalid': {
      ...presets.invalidField(theme),
    },
    '&:not(.showFocus)': {
      '&, &:focus-within': {
        outline:   'none',
        boxShadow: 'none',
      },
    },
  },

  container: {
    flex: [1, 1, 'auto'],
  },

  dropzoneContent: {
    position: 'relative',
  },

  markdownEditor: ({height}: any) => ({
    flex: [1, 1, 'auto'],
    minHeight,
    height,
    // $.richtTextField has field styles already
    ...presets.unstyledField(theme),
  }),

  body: ({height = minHeight}: any) => ({
    flex: [1, 1, 'auto'],
    '& .DraftEditor-root': {
      flex: [1, 1, 'auto'],
      minHeight,
      height,
      overflow: 'auto',
    },

    '& .public-DraftEditor-content': {
      padding: layout.padding.inline.l,
    },

    '& .DraftEditor-editorContainer, & .public-DraftEditor-content': {
      height: '100%',
    },

    '& .public-DraftEditorPlaceholder-root': {
      position:      'absolute',
      color:         theme.fg.placeholder,
      fontWeight:    400,
      padding:       layout.padding.inline.l,
      pointerEvents: 'none',
    },

    // Markdown works with paragraphs, DraftJS works with blocks. Apply paragraph styles to blocks
    // to ensure the same spacing.
    '& [data-block]:not(:first-child):not(ol):not(ul):not(li)': {
      marginTop: '1.2em',
    },
  }),

  toolbar: {
    position:  'relative',
    zIndex:    1, // To place above shadow

    background: theme.fg.dimmer,
    cursor:     'default',

    '$richTextField:focus-within &': {
      boxShadow:  [0, 3, 2, -2, shadows.shadowColor.alpha(0.2)],
      background: theme.semantic.primary,
    },
  },
}))