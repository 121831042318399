import React from 'react'
import { useTranslation } from 'react-i18next'
import { Media, Participant } from '~/models'
import { bewizrStore, profileStore, projectStore } from '~/stores'
import { ProfilePhotoField } from '~/ui/app/fields'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Center, Label, PushButton, TextBlock, TextField, VBox } from '~/ui/components'
import { InlineForm, InlineFormField } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import ParticipantConnection from './ParticipantConnection'
import ProfileFormModel from './ProfileFormModel'
import ProfileTable from './ProfileTable'
import ScoresTable from './ScoresTable'

export interface Props {
  participant: Participant
  editable?:   boolean
}

const ParticipantProfile = observer('ParticipantProfile', (props: Props) => {

  const {participant, editable = false} = props
  const [t] = useTranslation('participants')

  const formModel = React.useMemo(
    () => !editable ? null : new ProfileFormModel(participant),
    [editable, participant],
  )

  const tabs = projectStore.tabs
  const privateChat = projectStore.feature('private-chat')?.enabled

  const hasConnectionsTab = React.useMemo(
    () => tabs.find(it => it.link.href.startsWith('//connections')),
    [tabs],
  )

  //------
  // Callbacks

  const updateProfilePhoto = React.useCallback((media: Media | null) => {
    return profileStore.updateProfile({
      photoURL: media?.url ?? null,
    })
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.l} classNames={$.ParticipantProfile}>
        <Center gap={layout.padding.s}>
          {editable ? renderPhotoField() : renderPhoto()}
          {editable ? renderInfoForm() : renderInfo()}
        </Center>
        <ProfileTable
          participant={participant}
          editable={editable}
        />
        {renderBeWizrAccountManagement()}
        {renderScores()}
        {hasConnectionsTab && (
          <ParticipantConnection
            participant={participant}
          />
        )}
        {privateChat && (
          <Center>
            <PushButton
              icon='chat'
              caption={t('profile.private_chat.caption')}
              href={`/chat#private:${participant.id}`}
            />
          </Center>
        )}
      </VBox>
    )
  }

  function renderPhoto() {
    return (
      <Avatar
        source={participant.photoURL}
        firstName={participant.firstName}
        lastName={participant.lastName}
        size={profilePhotoSize}
      />
    )
  }

  function renderPhotoField() {
    return (
      <ProfilePhotoField
        participant={participant}
        size={profilePhotoSize}
        requestUpdate={updateProfilePhoto}
      />
    )
  }

  function renderInfo() {
    const {name, headline, bio} = participant

    return (
      <VBox gap={layout.padding.s}>
        <VBox gap={layout.padding.inline.xs}>
          <Label h2 align='center'>
            {name}
          </Label>
          <Label dim>{participant.email}</Label>
          {headline != null && (
            <Label light dim align='center'>
              {headline}
            </Label>
          )}
        </VBox>
        {bio != null && (
          <TextBlock small align='center'>
            {bio}
          </TextBlock>
        )}
      </VBox>
    )
  }

  function renderInfoForm() {
    if (formModel == null) { return null }

    return (
      <InlineForm fieldGap={layout.padding.s} model={formModel}>
        <VBox gap={layout.padding.inline.s}>
            <Label h2 align='center'>
              {participant.name}
            </Label>
            <Label dim>{participant.email}</Label>
          </VBox>
        <VBox gap={layout.padding.inline.xs}>
          <InlineFormField
            name='headline'
            align='center'
            renderView={headline => (
              <Label light dim italic={headline.length === 0} align='center'>
                {headline.length === 0 ? t('profile.headline_empty') : headline}
              </Label>
            )}
            renderEdit={bind => <TextField {...bind}/>}
          />
        </VBox>
        <VBox>
          <InlineFormField
            name='bio'
            align='center'
            renderView={bio => (
              <TextBlock small dim={bio.length === 0} italic={bio.length === 0} align='center'>
                {bio.length === 0 ? t('profile.bio_empty') : bio}
              </TextBlock>
            )}
            renderEdit={bind => <TextField multiline height={120} {...bind}/>}
          />
        </VBox>
      </InlineForm>
    )
  }

  function renderBeWizrAccountManagement() {
    if (!editable || !bewizrStore.authenticated) { return null }
    return (
      <VBox gap={layout.padding.xs} padding={layout.padding.s}>
        <PushButton
          icon='cog'
          href='https://be.wizr.eu/profile/'
          caption={t('bewizr-account.button.caption')}
        />
        <TextBlock tiny dim>
          {t('bewizr-account.button.instruction')}
        </TextBlock>
      </VBox>
    )
  }

  function renderScores() {
    if (participant.scores == null || participant.scores.length === 0) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <Label
          caption
          align='center'
          children={t('profile.scores.title')}
        />
        <ScoresTable
          participant={participant}
        />
      </VBox>
    )
  }

  return render()

})

export default ParticipantProfile

export const width = layout.contentWidth - 2 * layout.padding.inline.l

export const profilePhotoSize = {
    width:  180,
    height: 180,
  }

const useStyles = createUseStyles({
  ParticipantProfile: {
    width: width,
    maxWidth: '100%',
  },
})