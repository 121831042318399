import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { DateTimeRangeDisplay, HBox, SVG, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import BeWizrTile from '../BeWizrTile'

export interface Props {
  meetingID:     string
  occurrenceID?: string
  title:         string
  startDate?:    DateTime
  endDate?:      DateTime
  image:         string | null
  horizontal?:   boolean
  locked?:       boolean
}

const BeWizrMeetingTile = memo('BeWizrMeetingTile', (props: Props) => {

  const {meetingID, occurrenceID, title, startDate, endDate, image, horizontal, locked} = props

  const [t] = useTranslation('bewizr')

  const href = React.useMemo(() => {
    const baseHref = `//bewizr-meeting/${meetingID}`
    if (occurrenceID == null) { return baseHref }

    return `${baseHref}/occurrences/${occurrenceID}`
  }, [meetingID, occurrenceID])

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={title}
        subtitle={renderInfo()}
        icon='calendar'
        href={href}
        image={image}
        buttonCaption={t('meetings.view')}
        horizontal={horizontal}
        locked={locked}
      />
    )
  }

  function renderInfo() {
    if (startDate == null || endDate == null) { return null }

    return (
      <VBox gap={layout.padding.inline.xs}>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='calendar' dim size={layout.icon.s}/>
          <DateTimeRangeDisplay
            start={startDate}
            end={endDate}
            timeFormat={false}
            dim
            small
          />
        </HBox>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='clock' dim size={layout.icon.s}/>
          <DateTimeRangeDisplay
            start={startDate}
            end={endDate}
            dateFormat={false}
            dim
            small
          />
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default BeWizrMeetingTile