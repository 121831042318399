import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { ProgressBar } from '~/ui/components'

export interface Props {
  progress: number
}

const BeWizrCourseProgressBar = memo('BeWizrCourseProgressBar', (props: Props) => {

  const {progress} = props

  const [t] = useTranslation('bewizr')

  const completed = progress === 100
  const started   = !completed && progress > 0

  const caption = t(
    `courses.progress.bar.${completed ? 'completed' : started ? 'in_progress' : 'not_started'}`,
    {progress},
  )

  //------
  // Rendering

  function render() {
    return (
      <ProgressBar
        height={18}
        caption={caption}
        progress={progress}
        max={100}
      />
    )
  }

  return render()

})

export default BeWizrCourseProgressBar