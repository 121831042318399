import React from 'react'
import { useTranslation } from 'react-i18next'
import { bewizrAgendaStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Panel } from '~/ui/components'
import BeWizrAgendaView from '../../bewizr-agenda/BeWizrAgendaView'
import HomeScreenWidget from './HomeScreenWidget'

export interface Props {
}

const AgendaWidget = observer('AgendaWidget', (props: Props) => {

  const [t] = useTranslation('home')

  const endpoint    = bewizrAgendaStore.myActivities
  const fetchStatus = endpoint.fetchStatus
  const items       = endpoint.data

  React.useEffect(() => {
    endpoint.fetch()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    return (
      <HomeScreenWidget title={t('agenda')}>
        {items.length === 0 ? renderEmpty() : renderContent()}
      </HomeScreenWidget>

    )
  }

  function renderContent() {
    return (
      <BeWizrAgendaView
        items={items}
      />
    )
  }

  function renderEmpty() {
    return (
      <Panel>
        <EmptyOrFetching
          {...t('agenda:empty')}
          padded={false}
          status={fetchStatus}
        />
      </Panel>
    )
  }

  return render()

})

export default AgendaWidget