import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { combinedFetchStatus } from '~/lib/mobx-document'
import { resourceNameForItem, searchStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  EmptyOrFetching,
  HBox,
  Label,
  List,
  ListSection,
  SearchField,
  Spinner,
  VBox,
} from '~/ui/components'
import { useQueryParam } from '~/ui/hooks'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import { Breadcrumb } from '../navigation/types'
import { renderSearchResult } from './results'

export interface SearchParams {
  resource?: string
}

export type Props = RouteComponentProps<SearchParams>

const SearchScreen = observer('SearchScreen', (props: Props) => {

  const {resource} = props.match.params

  const [t] = useTranslation('search')

  const [query, setQuery] = useQueryParam('query')

  const allEndpoints = searchStore.allEndpoints
  const endpoints    = searchStore.endpoints

  React.useEffect(() => {
    searchStore.search(query)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, allEndpoints])

  const fetchMore = React.useCallback(() => {
    if (resource == null) { return }

    const endpoint = searchStore.getEndpoint(resource)
    endpoint?.fetchMore()
  }, [resource])

  const fetchStatus = combinedFetchStatus(...allEndpoints.map(it => it.fetchStatus))

  const data = React.useMemo(() => {
    // For some reason, we need this for the data to be re-calculated after fetching
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = fetchStatus

    if (resource != null) {
      const endpoint = searchStore.getEndpoint(resource)
      return endpoint?.data ?? []
    }

    const sections: ListSection<any>[] = []
    endpoints.forEach((endpoint, name) => {
      if (endpoint.data.length === 0) { return }
      sections.push({
        name:  name,
        items: endpoint.data.slice(0, 10),
      })
    })

    return sections
  }, [resource, endpoints, fetchStatus])

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    const breadcrumbs: Breadcrumb[] = []
    breadcrumbs.push({
      caption: t('title'),
      href:    resource != null ? '/search' : null,
    })
    if (resource != null) {
      breadcrumbs.push({
        caption: t(resource),
        href:    null,
      })
    }
    return breadcrumbs
  }, [resource, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex>
        <AppLayoutConfig
          defaultBreadcrumbs={breadcrumbs}
          ActionsComponent={renderSpinner}
        />
        {renderHeader()}
        <List
          data={data}
          contentPadding={layout.padding.s}
          renderItem={renderItem}
          EmptyComponent={renderEmpty}
          renderSectionHeader={renderSectionHeader}
          renderSectionFooter={renderSectionFooter}
          itemGap={layout.padding.inline.s}
          onEndReached={fetchMore}
          scrollable
        />
      </VBox>
    )
  }

  function renderSpinner() {
    if (fetchStatus !== 'fetching') { return null }
    return (
      <Spinner dim size={12}/>
    )
  }

  function renderItem(item: any) {
    const resource = resourceNameForItem(item)
    if (resource == null) { return null }

    return renderSearchResult(resource, item)
  }

  function renderHeader() {
    return (
      <VBox padding={layout.padding.s}>
        <SearchField
          autoFocus
          onSearch={setQuery}
          initialSearch={query}
          debounce={200}
        />
      </VBox>
    )
  }

  const renderEmpty = React.useCallback(() => {
    if (fetchStatus === 'fetching' && data.length > 0) { return null }

    return(
      <EmptyOrFetching
        flex
        status={fetchStatus}
        {...t(`empty.${query == null ? 'without_query' : 'with_query'}`)}
      />
    )
  }, [query, data, fetchStatus, t])

  function renderSectionHeader(section: ListSection<any>) {
    const index = data.findIndex(it => 'items' in it && it.name === section.name)
    return (
      <Label
        h3
        classNames={[$.sectionHeader, index === 0 && $.firstSectionHeader]}
        children={t(section.name)}
      />
    )
  }

  function renderSectionFooter(section: ListSection<any>) {
    const endpoint = searchStore.getEndpoint(section.name)
    if (endpoint == null) { return null }

    const resultsLeft = (endpoint.meta?.total ?? 0) - section.items.length

    if (resultsLeft <= 0 || resource != null) { return null }

    return (
      <HBox justify='center'>
        <ClearButton
          caption={t('show_more', {count: resultsLeft})}
          classNames={$.sectionFooter}
          href={`/search/${section.name}?query=${query}`}
        />
      </HBox>
    )
  }

  return render()

})

export default SearchScreen

const useStyles = createUseStyles({
  content: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),
  },

  sectionHeader: {
    paddingTop:    layout.padding.inline.l,
    paddingBottom: layout.padding.inline.m,
  },

  firstSectionHeader: {
    paddingTop: 0,
  },

  sectionFooter: {
    marginTop: layout.padding.inline.l,
  },
})