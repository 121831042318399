import { when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryItem } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrLibraryItemDocument from './BeWizrLibraryItemDocument'

export default class BeWizrLibraryItemsEndpoint extends Endpoint<BeWizrLibraryItemDocument, BeWirzLibraryItemsEndpointParams> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({
      offset: nextOffset,
      append: true,
    })
  }

  protected async performFetch(
    params:  BeWirzLibraryItemsEndpointParams,
    options: CollectionFetchOptions,
  ) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    const {query, category, topic} = params

    const response = await api.get('library/', {
      params: {q: query, category, topic, limit, offset},
    })
    if (response.status !== 200) { return response }

    const {items, count: total} = response.data
    const nextOffset = offset + limit > total ? null : offset + limit

    const data = items.map(
      (raw: any) => BeWizrLibraryItem.deserialize(raw),
    )

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}

export interface BeWirzLibraryItemsEndpointParams extends SearchParams {
  topic:    string
  category: string
}