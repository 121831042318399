import { DateTime } from 'luxon'
import { datetime } from '../serialization'
import { BeWizrModel } from './BeWizrModel'

export class BeWizrActivity extends BeWizrModel {

  public title!: string

  public links!: {
    self:  string
    image: string | null
  }

  @datetime()
  public startDate!: DateTime

  @datetime()
  public endDate!: DateTime

}