import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { BeWizrLibraryCategory } from '~/models'
import { bewizrStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Chip, EmptyOrFetching, HBox, List, ListBar, SVG } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'

export interface BeWizrLibraryCategoryParams {
  slug: string
}

export type Props = RouteComponentProps<BeWizrLibraryCategoryParams>

const BeWizrLibraryTopicScreen = observer('BeWizrLibraryTopicScreen', (props: Props) => {

  const {slug} = props.match.params

  const [t] = useTranslation('bewizr')

  const document = bewizrStore.topics.document(slug)
  const topic    = document.data

  const endpoint    = document.categoriesEndpoint
  const fetchStatus = endpoint == null ? document.fetchStatus : endpoint.fetchStatus

  const fetchMore = React.useCallback(() => {
    endpoint?.fetchMore()
  }, [endpoint])

  const fetch = React.useCallback(() => {
    if (endpoint != null) {
      endpoint?.fetch()
    } else {
      document.fetch()
    }
  }, [document, endpoint])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={topic?.title ?? null}
          fetchStatus={fetchStatus}
        />
        <List
          data={endpoint?.data ?? []}
          onEndReached={fetchMore}
          renderItem={renderCategory}
          EmptyComponent={renderEmpty}
          itemGap={layout.padding.inline.s}
          contentPadding={layout.padding.m}
          scrollable
        />
      </>
    )
  }

  const renderEmpty = React.useCallback(() => (
    <EmptyOrFetching
      {...t('library.categories.empty')}
      status={fetchStatus}
      flex
    />
  ), [t, fetchStatus])

  function renderCategory(category: BeWizrLibraryCategory) {
    return (
      <BeWizrLibraryCategoryListItem
        category={category}
      />
    )
  }

  return render()

})

export default BeWizrLibraryTopicScreen

interface BeWizrLibraryCategoryListItemProps {
  category: BeWizrLibraryCategory
}

const BeWizrLibraryCategoryListItem = memo('BeWizrLibraryCategoryListItem', (props: BeWizrLibraryCategoryListItemProps) => {

  const {category} = props

  function render() {
    return (
      <ListBar
        caption={category.title}
        accessory={renderAccessory()}
        href={`/bewizr-library/-/${category.topic.slug}/${category.slug}`}
      />
    )
  }

  function renderAccessory() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <Chip dim>
          {category.itemCount}
        </Chip>
        <SVG name='chevron-right' size={layout.icon.m} dimmer/>
      </HBox>
    )
  }

  return render()

})