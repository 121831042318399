import { observable, runInAction } from 'mobx'
import { Document } from 'mobx-document'
import { BeWizrUser, Participant } from '~/models'
import participantsStore from '../participantsStore'

export default class BeWizrUserDocument extends Document<BeWizrUser> {

  protected async performFetch() {
    // Not supported
    return null
  }

  @observable
  public participant: Participant | null = null

  public async resolveParticipant() {
    if (this.data == null) { return null }

    if (this.participant == null) {
      const participant = await participantsStore.findByEmail(this.data.email)
      runInAction(() => {
        this.participant = participant
      })
    }
    return this.participant
  }

}