import { CollectionFetchOptions, Database, Endpoint } from '~/lib/mobx-document'
import { Sender } from '~/models'
import ChatService from '../ChatService'
import SenderDocument from './SenderDocument'

export default class SendersEndpoint extends Endpoint<SenderDocument, {}, SendersMeta> {
  constructor(
    database: Database<SenderDocument>,
    public readonly service: ChatService,
  ) {
    super(database, {}, {})
  }

  public async performFetch(params: {}, options: CollectionFetchOptions) {
    const response = await this.service.fetchSenders(options.offset, options.limit)
    if (!response.ok) { return response }

    const {senders, ...rest} = response.body
    const data = senders as Sender[]
    const meta = rest

    return {data, meta}
  }

  public async fetchMore() {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextOffset == null) { return }

    await this.fetch({
      offset: this.meta.nextOffset,
      append: true,
    })
  }
}

export interface SendersMeta {
  count:       number
  nextOffset:  number | null
  offset:      number
  total:       number
}