import React from 'react'
import { memo } from '~/ui/component'
import ErrorScreen from './ErrorScreen'

const PageNotFoundScreen = memo('PageNotFoundScreen', () => {

  function render() {
    return (
      <ErrorScreen
        status={404}
      />
    )
  }

  return render()

})

export default PageNotFoundScreen