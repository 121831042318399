import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { BeWizrMeetingDocument, bewizrStore, FormError, SubmitResult } from '~/stores'
import { FormModel } from '~/ui/form'

export class BeWizrMeetingEnrollmentFormModel implements FormModel {

  public constructor(
    private readonly meetingID: string,
    public occurrence: string | null = null,
  ) {
    makeAutoObservable(this)
    this.document = bewizrStore.meetings.document(this.meetingID)
  }

  public readonly document: BeWizrMeetingDocument

  //------
  // Submit

  public submitting: boolean = false

  public get maySubmit(): boolean {
    return this.occurrence != null && this.submitting === false
  }

  public async submit(): Promise<SubmitResult<any>> {
    if (this.occurrence == null) {
      return {
        status: 'invalid',
        errors: [{field: 'occurrence'}] as FormError[],
      }
    }
    this.submitting = true
    const action    = this.document.resolveOccurrence(this.occurrence)?.isEnrolled ? 'cancel' : 'enroll'
    const response  = await this.document[action](this.occurrence)

    runInAction(() => {
      this.submitting = false
    })

    if (response?.status === 200) {
      return {
        status: 'ok',
        data:   {action},
      }
    } else {
      return {
        status: 'error',
        error:  response == null ? new Error() : AxiosError.from(response),
      }
    }
  }
}