import { CustomImage, Message, MessageStatus, Sender } from '~/models'

export interface ChatServiceOptions {
  initialChat?: ChatUri | null
}

export type ChatUri = `${'channel' | 'private'}:${string}`

export interface ChatDescriptor {
  uri: ChatUri
  type: ChatType

  name: string
  image: CustomImage | null

  open: boolean
  isModerated: boolean
  isModerator: boolean

  pinIndex: number | null
  createdAt: Date
}

export interface ChatState {
  unreadCounts: Record<ChatUri, number>
  totalUnreadCount: number
}

export const ChatState = {
  empty: (): ChatState => ({
    unreadCounts: {},
    totalUnreadCount: 0,
  }),
}


export interface MessagePayload {
  chat:    ChatUri
  message: AnyObject // Serialized
  sender:  Sender
  previousMessageID: string | null
}

export interface IncomingMessage {
  message: Message
  sender:  Sender
  chat:    ChatDescriptor
}

export interface StatusPayload {
  statuses: MessageStatusUpdate[]
}

export interface MessageStatusUpdate {
  chat:        ChatUri
  messageID:   string
  status:      MessageStatus
  answeredAt?: string | null
}


export interface TypingPayload {
  chat:       ChatUri
  sender:     Sender
  transition: 'start' | 'stop'
}

export interface TypingPayload {
  channelID: string
  sender:    Sender
}

export type ChatType = 'group' | 'participant' | 'private'

export type IncomingMessageListener = (message: IncomingMessage) => any