import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import { Database } from 'mobx-document'
import Semaphore from 'semaphore'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import {
  FeedbackMediaType,
  feedbackMediaTypeForMimeType,
  PendingMessageResource,
  PendingMessageTemplate,
  Sender,
} from '~/models'
import { resolveHref } from '~/navigation'
import chatStore from '../chatStore'
import ChatBackend from './ChatBackend'
import PrivateChatsEndpoint, { PrivateChatDocument } from './PrivateChatsEndpoint'
import {
  ChatDescriptor,
  ChatServiceOptions,
  ChatState,
  ChatUri,
  IncomingMessageListener,
  MessagePayload,
  StatusPayload,
  TypingPayload,
} from './types'

export default class ChatService extends OnDemandService {

  constructor(
    socket: Socket,
    public readonly profileID: string,
    options: ChatServiceOptions = {},
  ) {
    super(socket)

    this.requestedChatUri = options.initialChat ?? null

    makeObservable(this)
    this.disposers.push(autorun(() => this.moveOutOfNotExistingChat()))
    this.disposers.push(reaction(() => this.privateChatsEndpoint.data, () => {
      this.syncBackends(this.privateChatBackends, this.privateChatsEndpoint.data)
    }))
  }

  private disposers: IReactionDisposer[] = []

  public dispose() {
    this.disposers.forEach(it => it())
  }

  private chatStarted = new Semaphore()

  // #region Lifecycle

  public async start() {
    await super.startWithEvent('chat:start')
  }

  protected onStarted = (response: StartSuccess<InitialData>) => {
    this.socket.prefix = `chat:${this.uid}:`
    this.socket.addEventListener('channels', this.onChannels)
    this.socket.addEventListener('private:create', this.onPrivateChatCreate)
    this.socket.addEventListener('private:delete', this.onPrivateChatDelete)
    this.socket.addEventListener('message', this.onIncomingMessage)
    this.socket.addEventListener('status', this.onStatus)
    this.socket.addEventListener('typing:start', this.onStartTyping)
    this.socket.addEventListener('typing:stop', this.onStopTyping)

    this.channelChatBackends = response.data.channels.map(it => this.createBackend(it))
    this.privateChatsEndpoint.replace(response.data.private.chats, {
      nextPageToken: response.data.private.nextPageToken,
    })
    this.chatStarted.signal()
  }

  public onStop() {
    this.channelChatBackends = []
    this.privateChats.clear()
    this.chatStarted.reset()
  }

  // #endregion

  // #region Chats

  public readonly privateChats = new Database<PrivateChatDocument>({
    getID: (chat) => chat.uri,
    getDocument: chat => new PrivateChatDocument(chat.uri, {initialData: chat}),
    emptyDocument: id => new PrivateChatDocument(id),
  })


  public readonly privateChatsEndpoint = new PrivateChatsEndpoint(this.privateChats, this)

  @observable
  private privateChatBackends: ChatBackend[] = []

  @observable
  private channelChatBackends: ChatBackend[] = []

  @computed
  public get chats() {
    return [...this.channelChatBackends, ...this.privateChatBackends]
      .sort((a, b) => b.timestamp - a.timestamp)
  }

  public chat(uri: ChatUri) {
    return this.chats.find(it => it.uri === uri) ?? null
  }

  @computed
  public get totalUnreadCount() {
    return this.chats.reduce(
      (total, chat) => total + chat.unreadCount,
      0,
    )
  }

  // #endregion

  // #region State

  @observable
  private _state: ChatState = ChatState.empty()
  public get state() { return this._state }

  @action
  protected mergeState(update: ChatState) {
    Object.assign(this._state, {
      unreadCounts:     {...this._state.unreadCounts, ...update.unreadCounts},
      totalUnreadCount: update.totalUnreadCount,
    })
  }

  // #endregion

  // #region Chat switching & backends

  @observable
  private requestedChatUri: ChatUri | null = null

  @computed
  public get currentChatUri(): ChatUri | null {
    if (this.requestedChatUri != null) {
      return this.requestedChatUri
    } else if (this.chats.length === 1) {
      return this.chats[0].uri
    } else {
      return null
    }
  }

  @computed
  public get currentChat() {
    if (this.currentChatUri == null) { return null }

    const chat = this.chats.find(it => it.uri === this.currentChatUri)
    if (chat != null) { return chat }

    if (this.currentChatUri.startsWith('private:')) {
      return this.loadingPrivateChat
    } else {
      return null
    }
  }

  public async ensurePrivateChat(participantID: string) {
    await this.chatStarted
    this.socket.emit('private:ensure', participantID)
  }

  @computed
  private get loadingPrivateChat() {
    if (this.currentChatUri == null) { return null }

    const [type] = this.currentChatUri.split(':')
    if (type !== 'private') { return null }

    return this.createBackend({
      uri:         this.currentChatUri,
      type:        'private',
      name:        '',
      image:       null,
      open:        true,
      isModerated: false,
      isModerator: false,
      pinIndex:    null,
      createdAt:   new Date(),
    })
  }

  @action
  public switchToChat(uri: ChatUri | null) {
    if (this.requestChatHandler != null) {
      this.requestChatHandler(uri)
    } else {
      this.requestedChatUri = uri
    }
  }

  @action
  private moveOutOfNotExistingChat() {
    if (this.currentChatUri == null) { return }
    if (this.chat(this.currentChatUri) != null) { return }
    if (this.currentChatUri.startsWith('private:')) { return }

    this.requestedChatUri = null
  }

  private requestChatHandler: ((uri: ChatUri | null) => void) | null = null

  @action
  public syncWithAppNavigation(requestedUri: ChatUri, onRequestChat: (uri: ChatUri | null) => void) {
    this.requestChatHandler = onRequestChat
    if (this.currentChatUri !== requestedUri) {
      this.requestedChatUri = requestedUri
    }

    return () => {
      if (this.requestChatHandler === onRequestChat) {
        this.requestChatHandler = null
      }
    }
  }

  // #endregion

  // #region Senders

  @computed
  public get sender(): Sender {
    return chatStore.sender
  }

  public async fetchSenders(offset: number | null | undefined = 0, limit: number | null | undefined = 20) {
    const response = await this.socket.fetch('senders', this.currentChatUri, {
      limit:  limit,
      offset: offset,
    })

    return response
  }

  // #endregion

  // #region Socket listeners

  @action
  private onChannels = (channels: ChatDescriptor[], state: ChatState) => {
    this.syncBackends(this.channelChatBackends, channels)
    this.mergeState(state)
  }

  @action
  private onPrivateChatCreate = (descriptor: ChatDescriptor, state: ChatState) => {
    this.privateChatsEndpoint.append([descriptor])
    this.mergeState(state)
  }

  @action
  private onPrivateChatDelete = (payload: ChatUri) => {
    this.privateChats.delete(payload)
  }

  private syncBackends(backends: ChatBackend[], descriptors: ChatDescriptor[]) {
    const toRemove = new Set(backends.map(it => it.uri))
    for (const descriptor of descriptors) {
      const existing = backends.find(it => it.uri === descriptor.uri)
      if (existing == null) {
        backends.push(this.createBackend(descriptor))
      }

      toRemove.delete(descriptor.uri)
    }

    for (const uri of toRemove) {
      const index = backends.findIndex(it => it.uri === uri)
      backends.splice(index, 1)
    }
  }

  private createBackend(descriptor: ChatDescriptor) {
    if (typeof descriptor.createdAt === 'string') {
      descriptor.createdAt = new Date(descriptor.createdAt)
    }

    const backend = new ChatBackend(this, descriptor)
    backend.fetchNewMessages()
    return backend
  }

  @action
  private onIncomingMessage = (payload: MessagePayload) => {
    const chatBackend = this.chat(payload.chat)
    if (chatBackend == null) { return }

    chatBackend.handleIncomingMessage(payload).then(messages => {
      for (const message of messages) {
        const sender = chatBackend.senders.get(message.from)
        if (sender == null) { continue }

        for (const listener of this.incomingMessageListeners) {
          listener({message, sender, chat: chatBackend.descriptor})
        }
      }
    })
  }

  @action
  private onStatus = (payload: StatusPayload) => {
    for (const update of payload.statuses) {
      const chat = this.chat(update.chat)
      chat?.updateMessageStatus(update)
    }
  }

  private incomingMessageListeners = new Set<IncomingMessageListener>()

  public addIncomingMessageListener(listener: IncomingMessageListener) {
    this.incomingMessageListeners.add(listener)
    return () => { this.incomingMessageListeners.delete(listener) }
  }

  //------
  // Typing

  @action
  private onStartTyping = (payload: TypingPayload) => {
    const channel = this.chat(payload.chat)
    channel?.onStartTyping(payload.sender)
  }

  @action
  private onStopTyping = (payload: TypingPayload) => {
    const channel = this.chat(payload.chat)
    channel?.onStopTyping(payload.sender.id)
  }

  //------
  // Links

  public resolveHref(href: string) {
    return resolveHref(href)
  }

  //------
  // Media messages

  public async buildPendingMediaMessageTemplate(file: File, allowedFeedbackMediaTypes: FeedbackMediaType[]): Promise<Partial<PendingMessageTemplate> | null> {
    const mediaType = feedbackMediaTypeForMimeType(file.type)
    if (mediaType == null || !allowedFeedbackMediaTypes.includes(mediaType)) {
      return null
    }

    const resource: PendingMessageResource = {
      filename: file.name,
      mimeType: file.type,
      binary:   file,
    }

    if (mediaType === 'image') {
      return {type: 'image', image: resource}
    } else {
      return {type: 'video', video: resource}
    }
  }

  public readMediaAsBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = () => reject(reader.error)
      reader.onload  = () => {
        const dataURL: string = reader.result as string
        const base64 = dataURL.replace(/^data:.*;base64,/, '')
        resolve(base64)
      }

      reader.readAsDataURL(blob)
    })
  }

}

export interface InitialData {
  channels: ChatDescriptor[]
  private: {
    chats: ChatDescriptor[]
    nextPageToken: string | null
  }
  state: ChatState
}