import React from 'react'
import { ImageView, ImageViewProps } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedComponent, Label, Tappable, TappableProps, VBox, VBoxProps } from '~/ui/components'
import {
  animation,
  createUseStyles,
  layout,
  shadows,
  themedBackgroundStyles,
  themeOptionsForBackground,
  ThemeProvider,
  TileBranding,
  useStyling,
} from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props extends VBoxProps {
  image?:     ImageViewProps['source'] | null
  label?:     React.ReactNode
  detail?:    React.ReactNode
  accessory?: React.ReactNode

  href?:     TappableProps['href']
  target?:   TappableProps['target']
  onTap?:    TappableProps['onTap']

  align?:     TileAlign
  minHeight?: number
  branding?:  TileBranding
}

export type TileAlign = 'left' | 'center' | 'right'

const Tile = memo('Tile', (props: Props) => {

  const {guide} = useStyling()

  const {
    image,
    label,
    detail,
    accessory,

    href,
    target,
    onTap,

    width,

    align = 'left',
    style,
    minHeight = defaultMinHeight,
    branding = guide.tile,

    classNames,
    ...rest
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (href != null || onTap != null) {
      return (
        <Tappable classNames={$.tappable} href={href} target={target} onTap={onTap}>
          {renderTile(true)}
        </Tappable>
      )
    } else {
      return renderTile(false)
    }
  }

  function renderTile(interactive: boolean) {
    return (
      <BrandedComponent classNames={[$.tile, {interactive}, classNames]} branding={branding} height={minHeight} style={{minHeight}}>
        {renderBackground()}
        {renderContent()}
      </BrandedComponent>
    )
  }

  function renderBackground() {
    return (
      <VBox classNames={$.background} flex>
        {image != null && (
          <ImageView
            source={image}
            objectFit='cover'
            flex
          />
        )}
      </VBox>
    )
  }

  function renderContent() {
    return (
      <VBox justify='bottom' {...rest} classNames={$.content}>
        {renderInfo()}
        {renderAccessory()}
      </VBox>
    )
  }

  function renderInfo() {
    return (
      <ThemeProvider {...themeOptionsForBackground(guide, branding.info.background())}>
        <VBox classNames={$.info} gap={layout.padding.inline.s}>
          {renderLabel()}
          {renderDetail()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderLabel() {
    if (!isReactText(label)) { return label }

    return (
      <Label bold truncate={false} align={align}>
        {label}
      </Label>
    )
  }

  function renderDetail() {
    if (!isReactText(detail)) { return detail }

    return (
      <Label tiny dim align={align}>
        {detail}
      </Label>
    )
  }

  function renderAccessory() {
    if (accessory == null) { return null }

    return (
      <VBox>
        {accessory}
      </VBox>
    )
  }

  return render()

})

export default Tile

export const defaultMinHeight = 96

const useStyles = createUseStyles(theme => {
  const borderRadius = theme.guide.tile.borderRadius(defaultMinHeight)
  const depth        = theme.guide.tile.resolve('depth') ?? 1

  return {
    tappable: {
      '&, & $tile, & $background': {
        borderRadius: borderRadius,
      },
    },

    tile: {
      willChange: 'box-shadow',
      transition: animation.transitions.short('box-shadow'),
      '&.interactive:hover': {
        boxShadow: shadows.depth(Math.ceil(depth * 1.5)),
      },
    },

    background: {
      borderBottomLeftRadius:  0,
      borderBottomRightRadius: 0,

      overflow: 'hidden',
    },

    content: {
      overflow: 'hidden',
      marginTop: -layout.radius.l,
      borderBottomLeftRadius:  borderRadius,
      borderBottomRightRadius: borderRadius,
    },

    info: {
      padding: layout.padding.inline.m,
      ...themedBackgroundStyles(theme.guide, theme.guide.tile.info.background()),
    },
  }
})