import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuPage, MenuPageItem } from '~/models'
import { infoStore } from '~/stores'
import { observer } from '~/ui/component'
import { Grid, Tile } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import HomeScreenWidget from './HomeScreenWidget'

export interface Props {
}

const InfoMenuWidget = observer('InfoMenuWidget', (props: Props) => {

  const [t] = useTranslation('home')

  const document = infoStore.pages.document('')

  React.useEffect(() => {
    document.fetch()
  }, [document])

  const page = document.data

  const items = page instanceof MenuPage ? page.items ?? [] : []

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HomeScreenWidget title={t('info')}>
        <Grid columns={`repeat(auto-fit, minmax(${minTileWidth}px, 1fr))`} gap={layout.padding.inline.l}>
          {items.map(renderTile)}
        </Grid>
      </HomeScreenWidget>
    )
  }

  function renderTile(item: MenuPageItem) {
    return (
      <Tile
        key={item.link.href}
        image={item.image}
        label={item.caption}
        align='center'
        classNames={$.tile}
        {...item.link}
      />
    )
  }

  return render()

})

export default InfoMenuWidget

const minTileWidth  = 160

const useStyles = createUseStyles({
  tile: {
    aspectRatio: '1 / 1.1',
  },
})