import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { FetchStatus } from 'mobx-document'
import { OnDemandServiceStatus } from 'socket.io-react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { ErrorScreen } from '../app/errors'
import ClearButton from './ClearButton'
import Empty, { Props as EmptyProps } from './Empty'
import { Label } from './label'
import { Center } from './layout'
import Spinner from './Spinner'

export interface Props extends Omit<EmptyProps, 'title' | 'detail'> {
  status: FetchStatus | OnDemandServiceStatus
  retry?: () => any

  title?:  EmptyProps['title']
  detail?: EmptyProps['detail']
}

const EmptyOrFetching = memo('EmptyOrFetching', (props: Props) => {

  const {status, flex, retry, title, detail, ...rest} = props
  const httpStatus = status instanceof Error && hasErrorStatus(status) ? status.status : null

  const [t] = useTranslation('errors')
  const labels = React.useMemo((): Pick<EmptyProps, 'title' | 'detail'> => {
    if (title != null) {
      return {title, detail}
    } else {
      return t('empty')
    }
  }, [detail, t, title])

  //------
  // Rendering

  function render() {
    if (status === 'idle') { return null }

    if (status === 'fetching' || status === 'starting') {
      return renderWorking()
    } else if (status instanceof Error) {
      return (
        <VBox flex={flex} padding={layout.padding.l} classNames={props.classNames}>
          <ErrorScreen
            flex={flex}
            status={httpStatus ?? 500}
          />
        </VBox>
      )
    } else {
      return (
        <VBox flex={flex} padding={layout.padding.l} classNames={props.classNames}>
          <Empty
            flex={flex}
            {...labels}
            children={renderChildren()}
            {...rest}
          />
        </VBox>
      )
    }
  }

  function renderWorking() {
    return (
      <Center flex={flex} classNames={props.classNames}>
        <Spinner/>
      </Center>
    )
  }

  function renderChildren() {
    if (httpStatus === 404) {
      return (
        <NavLink to='/'>
          <Label link small>
            {t('404.link')}
          </Label>
        </NavLink>
      )
    } else if (retry != null) {
      return (
        <ClearButton
          caption={t('buttons:retry')}
          onTap={retry}
        />
      )
    }
  }

  return render()

})

export default EmptyOrFetching

function hasErrorStatus(error: Error): error is Error & {status: number} {
  return typeof (error as any).status === 'number'
}