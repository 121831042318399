import React from 'react'
import Color from 'color'
import { ClearButton, HBox, Label, LabelProps, Spinner, SVG, Tappable } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props extends Omit<LabelProps, 'children' | 'tiny'> {
  icon?:     SVGName
  loading?:  boolean
  children?: React.ReactNode

  href?:          string
  onTap?:         () => any
  requestRemove?: () => any

  backgroundColor?: Color
}

const Chip = (props: Props) => {

  const {
    icon,
    children,
    loading,
    dim,
    caption = true,
    small,
    flex,
    backgroundColor,
    onTap,
    href,
    requestRemove,
    classNames: props_classNames,
    ...labelProps
  } = props

  const spinnerSize = small ? 4 : 8

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (onTap != null || href != null) {
      return (
        <Tappable onTap={onTap} href={href} classNames={props_classNames}>
          {renderChip(null)}
        </Tappable>
      )
    } else {
      return renderChip(props_classNames)
    }
  }

  function renderChip(extraClassNames: React.ClassNamesProp) {
    const classNames = [
      $.chip,
      {small, normal: !small, dim},
      extraClassNames,
    ]
    const style: React.CSSProperties = {
      backgroundColor: backgroundColor?.string(),
    }

    return (
      <ThemeProvider contrast={backgroundColor ?? 'secondary'}>
        <HBox classNames={classNames} flex={flex} gap={layout.padding.inline.s} style={style}>
          {renderIcon()}
          {loading ? (
            <Spinner size={spinnerSize}/>
          ) : (
            renderCaption()
          )}
          {renderRemoveButton()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    return (
      <SVG
        name={icon}
        size={small ? layout.icon.xs : layout.icon.s}
        classNames='--no-override-fg'
      />
    )
  }

  function renderCaption() {
    if (!isReactText(children)) { return children }

    return (
      <Label tiny={small} {...labelProps} flex='both' caption={caption} classNames={[$.caption, '--no-override-fg']} align='center'>
        {children}
      </Label>
    )
  }

  function renderRemoveButton() {
    if (requestRemove == null) { return null }

    return (
      <ClearButton
        classNames={$.removeButton}
        icon='cross'
        onTap={requestRemove}
        padding='both'
        showFocus={false}
        small
        round
      />
    )
  }

  return render()

}

export default Chip

export const height = {
  small:  18,
  normal: 24,
}
export const minWidth = {
  small:  24,
  normal: 32,
}

const useStyles = createUseStyles(theme => ({
  chip: {
    background: theme.semantic.secondary,
    cursor:     'default',

    '&.dim': {
      background: theme.semantic.secondary.alpha(0.6),
    },

    '&.normal': {
      height:       height.normal,
      borderRadius: height.normal / 2,
      minWidth:     minWidth.normal,
      padding:      [layout.padding.inline.xs, layout.padding.inline.m],
    },

    '&.small': {
      height:       height.small,
      borderRadius: height.small / 2,
      minWidth:     minWidth.small,
      padding:      [layout.padding.inline.xs, layout.padding.inline.s + 2],
    },
  },

  removeButton: {
    marginRight:  layout.padding.inline.m - layout.padding.inline.l,

    '&:focus::after': {
      background: theme.colors.bg.dark.subtle,
    },
  },

  caption: {
    lineHeight: '1em !important',
  },
}))