import React from 'react'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { BrandedLogo, HBox, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import AppSideBarFooter from './AppSideBarFooter'
import AppSideBarNavigationTabs from './AppSideBarNavigationTabs'

const AppSideBar = observer('AppSideBar', () => {

  const tabs = projectStore.tabs

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox tag='nav' padding={sideBarPadding} classNames={$.AppSideBar} gap={layout.padding.l} justify='space-between'>
        <VBox gap={layout.padding.xl}>
          {renderLogo()}
          <AppSideBarNavigationTabs/>
        </VBox>
        <AppSideBarFooter/>
      </VBox>
    )
  }

  function renderLogo() {
    return (
      <HBox>
        <Tappable href={tabs[0]?.link.href ?? '/'} noFeedback>
          <BrandedLogo
            size={logoSize}
            objectPosition='left'
          />
        </Tappable>
      </HBox>
    )
  }

  return render()

})

export default AppSideBar

const sideBarWidth = 270
const sideBarPadding = layout.padding.xl
const logoSize = {
  height: 50,
  width: sideBarWidth - sideBarPadding.desktop * 2,
}

const useStyles = createUseStyles(theme => ({
  AppSideBar: {
    borderRadius: 0,
    width: sideBarWidth,
    borderRight: [2, 'solid', theme.border.dimmer],
  },
}))