import React from 'react'
import { BeWizrMeetingOccurrence } from '~/models'
import { memo } from '~/ui/component'
import { DateTimeRangeDisplay, DateTimeRangeDisplayProps } from '~/ui/components'

export interface Props extends Omit<DateTimeRangeDisplayProps, 'start' | 'end'> {
  occurrence: BeWizrMeetingOccurrence
}

const BeWizrMeetingOccurrenceDateTimeView = memo('BeWizrMeetingOccurrenceDateTimeView', (props: Props) => {

  const {occurrence, ...rest} = props

  //------
  // Rendering

  function render() {
    return (
      <DateTimeRangeDisplay
        start={occurrence.startDate}
        end={occurrence.endDate}
        {...rest}
      />
    )
  }

  return render()

})

export default BeWizrMeetingOccurrenceDateTimeView