import { DateTime } from 'luxon'
import { datetime } from '../serialization'
import { BeWizrModel } from './BeWizrModel'
import { BeWizrUser } from './BeWizrUser'

export class BeWizrMeeting extends BeWizrModel {

  public title!:           string
  public descriptionHtml!: string

  public occurrences!: BeWizrMeetingOccurrence[]

  public links!: {
    image: string | null
  }

  protected afterDeserialize() {
    this.occurrences = this.raw.occurrences.map(
      (it: any) => BeWizrMeetingOccurrence.deserialize(it),
    )
  }
}

export class BeWizrMeetingOccurrence extends BeWizrModel {

  public isEnrolled!: boolean

  public links!: {
    enroll: string | null
    cancel: string | null
  }

  @datetime()
  public startDate!: DateTime

  @datetime()
  public endDate!: DateTime

  public isOnline!: boolean

  public location!: BeWizrLocation | null

  public facilitator!: BeWizrUser | null

  public get mayEnroll() {
    if (this.startDate < DateTime.now()) { return false }
    return this.links.enroll != null || this.links.cancel != null
  }

  protected afterDeserialize() {
    if (this.raw.facilitator == null) { return }
    this.facilitator = BeWizrUser.deserialize(this.raw.facilitator)
  }

}

export interface BeWizrLocation {

  title: string
  descriptionHtml: string

  addressLine:    string
  addressZip:     string
  addressCity:    string
  addressCountry: string

}