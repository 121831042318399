import { merge } from 'lodash'
// @index(\.yml$): import ${variable} from ${relpathwithext}
import agenda from './agenda.yml'
import app from './app.yml'
import auth from './auth.yml'
import bewizr from './bewizr.yml'
import challenges from './challenges.yml'
import chat from './chat.yml'
import components from './components.yml'
import config from './config.yml'
import connections from './connections.yml'
import errors from './errors.yml'
import home from './home.yml'
import info from './info.yml'
import link from './link.yml'
import misc from './misc.yml'
import news from './news.yml'
import notifications from './notifications.yml'
import participants from './participants.yml'
import qAndAs from './q-and-as.yml'
import registration from './registration.yml'
import schedule from './schedule.yml'
import search from './search.yml'
import splash from './splash.yml'
import validation from './validation.yml'
import webcast from './webcast.yml'
import widgets from './widgets.yml'

// /index

export default merge(
  // @index(\.yml$): ${variable},
  agenda,
  app,
  auth,
  bewizr,
  challenges,
  chat,
  components,
  config,
  connections,
  errors,
  home,
  info,
  link,
  misc,
  news,
  notifications,
  participants,
  qAndAs,
  registration,
  schedule,
  search,
  splash,
  validation,
  webcast,
  widgets,
  // /index
)