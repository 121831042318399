import i18n from 'i18next'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import socket, { SendResponse } from 'socket.io-react'
import { Participant, ProfileDatum } from '~/models'
import authenticationStore from './authenticationStore'
import bewizrStore from './bewizrStore'
import participantsStore from './participantsStore'
import { GroupSignup } from './profile'
import projectStore from './projectStore'
import { register, SubmitResult } from './support'
import { submitResultForResponse } from './support/responses'

export class ProfileStore {

  constructor() {
    makeObservable(this)
    reaction(() => projectStore.project?.languages, this.switchToAvailableLanguage.bind(this))
  }

  @observable
  public participantID: string | null = null

  @computed
  public get participant() {
    if (this.participantID == null) { return null }

    const document = participantsStore.participants.document(this.participantID)
    return document?.data ?? null
  }

  public requestUpdate() {
    socket.emit('profile:request')
  }

  @action
  private onLogOut = () => {
    this.participantID = null
  }

  //------
  // Groups

  public groupSignup(signupID: string, groupID: string) {
    return new GroupSignup(signupID, groupID)
  }

  //------
  // Editing

  @observable
  public editing: boolean = false

  @action
  public startEdit() {
    this.editing = true
  }

  @action
  public stopEdit() {
    this.editing = false
  }

  public getDatum(name: string): ProfileDatum | null {
    const {participant} = this
    if (participant == null) { return null }

    return participant.profileData?.find(datum => datum.name === name) ?? null
  }

  //------
  // Language

  @action
  public async switchLanguage(language: string) {
    if (this.participant == null) { return }

    const revert = this.optimisticallySwitchLanguage(language)

    const bewizrResponse = await bewizrStore.switchLanguage(language)
    if (bewizrResponse !== true) {
      revert()
      return
    }

    const profileResponse = await this.updateProfile({language})
    if (profileResponse.status !== 'ok') {
      revert()
    }
  }

  @action
  private optimisticallySwitchLanguage(language: string) {
    const prevLanguage = this.participant?.language
    if (this.participant != null) {
      this.participant.language = language
      i18n.changeLanguage(language)
    }

    return action(() => {
      if (this.participant == null || prevLanguage == null) { return }
       this.participant.language = prevLanguage
      i18n.changeLanguage(prevLanguage)
    })
  }

  @action
  private switchToAvailableLanguage() {
    const project     = projectStore.project
    const participant = this.participant
    if (project == null || participant == null) { return }

    const supportedLanguages = project.languages
    if (!supportedLanguages.includes(participant.language)) {
      this.switchLanguage(supportedLanguages.includes(i18n.language) ? i18n.language : supportedLanguages[0])
    } else if (i18n.language !== participant.language) {
      i18n.changeLanguage(participant.language)
    }
  }

  //------
  // Updates

  @action
  public async updateProfile(updates: AnyObject) {
    const response = await socket.send('profile:update', updates)
    return this.onUpdateProfileComplete(response)
  }

  @action
  private onUpdateProfileComplete = (response: SendResponse<{participant: Participant, weblink: boolean}>): SubmitResult => {
    if (response.ok) {
      const participant = Participant.deserialize(response.body.participant)
      const document    = participantsStore.participants.store(participant)
      this.participantID = document.id
    }

    return submitResultForResponse(response)
  }

  //------
  // Initialization

  public init() {
    authenticationStore.on('logout', this.onLogOut)
    socket.addEventListener('profile:update', this.onProfileUpdate)
  }

  @action
  private onProfileUpdate = async (json: AnyObject) => {
    const participant  = Participant.deserialize(json.participant)
    const document     = participantsStore.participants.store(participant)
    this.participantID = document.id

    this.switchToAvailableLanguage()
  }

}

export default register(new ProfileStore())