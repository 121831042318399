import React from 'react'
import { ScrollManager, ScrollManagerProvider, ScrollState } from 'react-scroll-manager'
import { useTimer } from 'react-timer'
import { isFunction, omit } from 'lodash'
import Modernizr from 'modernizr'
import { forwardRef } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { animation, createUseStyles, layout, shadows } from '~/ui/styling'
import { flexStyle } from '../layout/styles'
import ScrollerProxy, { Props as ScrollerProxyProps } from './ScrollerProxy'

export interface Props extends ScrollerProxyProps {
  scrollEnabled?:        boolean
  alwaysShowsScrollbar?: boolean

  touchLike?:  boolean
  horizontal?: boolean
  flex?:       VBoxProps['flex']
  overflow?:   number
  shadows?:    boolean

  scrollManager?:    ScrollManager
  scrollToEndUponFirstRender?: boolean

  children?:            React.ReactNode

  classNames?:          React.ClassNamesProp
  containerClassNames?: React.ClassNamesProp
  contentClassNames?:   React.ClassNamesProp
  contentAlign?:        React.CSSProperties['alignItems']
  contentJustify?:      React.CSSProperties['justifyContent']
  contentPadding?:      VBoxProps['padding']
}

const Scroller = forwardRef('Scroller', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    horizontal    = false,
    flex          = true,
    overflow,
    shadows       = true,
    scrollEnabled = true,
    touchLike     = false,
    alwaysShowsScrollbar = false,
    scrollToEndUponFirstRender = false,
    contentAlign = 'stretch',
    contentJustify,
    contentPadding,
    onScrollStateChange,
  } = props

  const defaultScrollManager = React.useMemo(() => new ScrollManager(), [])
  const scrollManager        = props.scrollManager ?? defaultScrollManager
  const containerRef         = React.useRef<HTMLDivElement>(null)

  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)

  const simulateTouch = touchLike && !Modernizr.touchevents

  const handleScrollStateChange = React.useMemo(() => {
    if (!shadows) {
      return onScrollStateChange
    } else {
      return (state: ScrollState) => {
        setScrollState(state)
        onScrollStateChange?.(state)
      }
    }
  }, [onScrollStateChange, shadows])

  React.useLayoutEffect(() => {
    scrollManager.setContainer(containerRef.current!)
    if (scrollToEndUponFirstRender) {
      scrollManager.scrollToEnd({behavior: 'instant'})
    }
    return () => { scrollManager.dispose() }
  }, [scrollManager, scrollToEndUponFirstRender])

  //------
  // Event handling

  const timer = useTimer()

  const dragActiveRef        = React.useRef<boolean>(false)
  const startMousePosRef     = React.useRef<number | null>(null)
  const startScrollOffsetRef = React.useRef<number | null>(null)
  const preventClickRef      = React.useRef<boolean>(false)

  const onMouseDown = React.useCallback((event: React.MouseEvent<any>) => {
    const container = containerRef.current
    if (container == null) { return }

    startMousePosRef.current     = event[props.horizontal ? 'pageX' : 'pageY']
    startScrollOffsetRef.current = container[props.horizontal ? 'scrollLeft' : 'scrollTop']
  }, [props.horizontal])

  const onMouseMove = React.useCallback((event: React.MouseEvent<any>) => {
    const container         = containerRef.current
    const startMousePos     = startMousePosRef.current
    const startScrollOffset = startScrollOffsetRef.current
    if (container == null || startMousePos == null || startScrollOffset == null) { return }

    const delta = startMousePos - event[props.horizontal ? 'pageX' : 'pageY']
    if (Math.abs(delta) > 2) {
      dragActiveRef.current = true
      const position = startScrollOffset + delta
      container[props.horizontal ? 'scrollLeft' : 'scrollTop'] = position
    }

    event.preventDefault()
  }, [props.horizontal])

  const onMouseUp = React.useCallback((event: React.MouseEvent<any>) => {
    if (dragActiveRef.current) {
      event.preventDefault()

      // Prevent a click temporarily.
      preventClickRef.current = true
      timer.setTimeout(() => { preventClickRef.current = false }, 0)
    }

    startMousePosRef.current = null
    startScrollOffsetRef.current = null
    dragActiveRef.current = false
  }, [timer])

  const onMouseUpCapture = React.useCallback((event: React.MouseEvent<any>) => {
    if (dragActiveRef.current && event.cancelable) {
      event.preventDefault()
    }
  }, [])

  const onClickCapture = React.useCallback((event: React.MouseEvent<any>) => {
    if (preventClickRef.current && event.cancelable) {
      event.preventDefault()
    }
    preventClickRef.current = false
  }, [])

  const onTouchEndCapture = React.useCallback((event: React.TouchEvent<any>) => {
    if (dragActiveRef.current && event.cancelable) {
      event.preventDefault()
    }
  }, [])

  const onMouseLeave = React.useCallback(() => {
    startMousePosRef.current = null
    startScrollOffsetRef.current = null
    dragActiveRef.current = false
  }, [])

  const eventHandlers = React.useMemo((): React.HTMLAttributes<any> => {
    const handlers: React.HTMLAttributes<any> = {}

    if (props.touchLike && props.scrollEnabled !== false) {
      Object.assign(handlers, {
        onMouseDown:       onMouseDown,
        onMouseMove:       onMouseMove,
        onMouseLeave:      onMouseLeave,
        onMouseUp:         onMouseUp,
        onMouseUpCapture:  onMouseUpCapture,
        onClickCapture:    onClickCapture,

        onTouchEndCapture: onTouchEndCapture,
      })
    }

    return handlers
  }, [props.touchLike, props.scrollEnabled, onMouseDown, onMouseMove, onMouseLeave, onMouseUp, onMouseUpCapture, onClickCapture, onTouchEndCapture])

  //------
  // Render

  const $ = useStyles({overflow, contentAlign, contentJustify})

  function render () {
    const directionClassName = horizontal ? 'horizontal' : 'vertical'
    const scrollerClassNames = [
      $.Scroller,
      directionClassName,
      {
        atStart:   scrollState.atStart,
        atEnd:     scrollState.atEnd,
        scrollbar: (scrollState.scrollbarWidth ?? 0) > 0,
      },
      props.classNames,
    ]

    const containerClassNames = [
      $.container,
      directionClassName,
      touchLike && $.touchLike,
      props.containerClassNames,
    ]

    const contentClassNames = [
      $.content,
      directionClassName,
      props.contentClassNames,
    ]

    const overflowStyleProp = horizontal ? 'overflowX' : 'overflowY'
    const overflowWhenScrolling = alwaysShowsScrollbar ? 'scroll' : 'auto'
    const scrollStyle: React.CSSProperties = scrollEnabled ? {
      [overflowStyleProp]: simulateTouch ? 'hidden' : overflowWhenScrolling,
    } : {
      [overflowStyleProp]: 'hidden',
    }

    const children = isFunction(props.children)
      ? props.children(scrollManager)
      : props.children

    return (
      <ScrollManagerProvider value={scrollManager}>
        <ScrollerProxy {...props} onScrollStateChange={handleScrollStateChange}>
          <VBox classNames={scrollerClassNames} flex={flex} ref={ref}>
            <div ref={containerRef} classNames={containerClassNames} style={{...scrollStyle, ...flexStyle(flex)}} {...eventHandlers}>
              <VBox classNames={contentClassNames} padding={contentPadding}>
                {children}
              </VBox>
            </div>
            <div classNames={[$.shadow, horizontal ? 'left' : 'top']}/>
            <div classNames={[$.shadow, horizontal ? 'right' : 'bottom']}/>
          </VBox>
        </ScrollerProxy>
      </ScrollManagerProvider>
    )
  }

  return render()

})

export default Scroller

const directionStyle = {
  '&.vertical':   {...omit(layout.flex.column, 'alignItems')},
  '&.horizontal': {...omit(layout.flex.row, 'alignItems')},
}

const useStyles = createUseStyles({
  Scroller: ({overflow}: any) => ({
    position:   'relative',
    alignItems: 'stretch',
    margin:     -overflow,
  }),

  shadow: {
    position: 'absolute',

    willChange: 'opacity',
    transition: animation.transitions.short('opacity'),

    '&.top, &.bottom': {
      left:   0,
      right:  0,
      height: 6,
    },

    '&.left, &.right': {
      top:    0,
      bottom: 0,
      width:  6,
    },

    '&.top':    {
      top:       0,
      boxShadow: ['inset', 0, 2, 6, -4, shadows.shadowColor],
    },
    '&.bottom': {
      bottom:    0,
      boxShadow: ['inset', 0, -2, 6, -4, shadows.shadowColor],
    },
    '&.left':    {
      left:      0,
      boxShadow: ['inset', 2, 0, 6, -4, shadows.shadowColor],
    },
    '&.right': {
      right:     0,
      boxShadow: ['inset', -2, 0, 6, -4, shadows.shadowColor],
    },

    opacity: 0,

    '$scroller.scrollbar > &.top, $scroller.scrollbar > &.bottom': {
      right: 15 + layout.padding.inline.m,
    },
    '$scroller.scrollbar > &.left, $scroller.scrollbar > &.right': {
      bottom: 15 + layout.padding.inline.m,
    },

    '$scroller:not(.atStart) > &.top, $scroller:not(.atStart) > &.left': {
      opacity: 1,
    },
    '$scroller:not(.atEnd) > &.bottom, $scroller:not(.atEnd) > &.right': {
      opacity: 1,
    },

    pointerEvents: 'none',
    overflow:      'hidden',
  },

  container: {
    display: 'flex',
    ...directionStyle,
    alignItems: 'stretch',

    '-webkit-overflow-scrolling': 'touch',
  },

  content: ({overflow, contentAlign, contentJustify}: any) => ({
    flex: [1, 0, 'auto'],
    ...directionStyle,
    padding: overflow,

    alignItems:     contentAlign,
    justifyContent: contentJustify,
  }),

  touchLike: {
    '&::-webkit-scrollbar': {
      display: 'none',
      backgroundColor: 'transparent',
      '-webkit-box-shadow': 'none',
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
      backgroundColor: 'transparent',
      '-webkit-box-shadow': 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
      backgroundColor: 'transparent',
      '-webkit-box-shadow': 'none',
    },
  },
})