import { when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrActivity } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrActivityDocument from './BeWizrActivityDocument'

export default class BeWizrAgendaEndPoint extends Endpoint<BeWizrActivityDocument, BeWizrAgendaEndpointParams> {

  protected async performFetch(
    params:  BeWizrAgendaEndpointParams,
    options: CollectionFetchOptions,
  ) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    const {query, year, month} = params

    const response = await api.get('agenda/', {
      params: {q: query, year, month, limit, offset},
    })
    if (response.status !== 200) { return response }

    const {items, count: total} = response.data
    const nextOffset = offset + limit > total ? null : offset + limit

    const data = items.map(
      (raw: any) => BeWizrActivity.deserialize(raw),
    )

    return {
      data: data,
      meta: {total, nextOffset},
    }

  }

}

export interface BeWizrAgendaEndpointParams extends SearchParams {
  year?:  number
  month?: number
}