import { DateTime } from 'luxon'
import { BeWizrActivity } from '~/models'

export function groupItemsByDay(items: BeWizrActivity[]): Map<DateTime, BeWizrActivity[]> {
  const result = new Map<DateTime, BeWizrActivity[]>()
  for (const item of items) {
    const cleanedDate = item.startDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})
    if (!result.has(cleanedDate)) {
      result.set(cleanedDate, [])
    }
    result.get(cleanedDate)!.push(item)
  }
  return result
}