import socket from 'socket.io-react'
import { Database } from '~/lib/mobx-document'
import { Sender } from '~/models'
import { profileStore } from '~/stores'
import { ChatService, ChatServiceOptions } from './chat'
import SenderDocument from './chat/senders/SenderDocument'
import { register } from './support'

export class ChatStore {

  public chatService(profileID: string, options: ChatServiceOptions = {}) {
    return new ChatService(socket, profileID, options)
  }

  public readonly senders = new Database<SenderDocument>({
    getID: (sender) => sender.id,
    getDocument: sender => new SenderDocument(sender.id, {initialData: sender}),
    emptyDocument: id => new SenderDocument(id),
  })

  //------
  // Sender

  public get sender(): Sender {
    const {participant} = profileStore
    if (participant == null) {
      throw new Error('Profile not loaded')
    }

    return {
      type:      'participant',
      id:        participant.id,
      firstName: participant.firstName,
      lastName:  participant.lastName,
      photoURL:  participant.photoURL,
      headline:  participant.headline,
    }
  }
}

export default register(new ChatStore())