import React from 'react'
import { isFunction } from 'lodash'

//------
// Measurements

export const padding = {
  inline: {
    xs: 2,
    s:  4,
    m:  8,
    l:  16,
    xl: 24,
  },

  xs:  {mobile: 4, tablet: 6, desktop: 6, widescreen: 6},
  s:   {mobile: 9, tablet: 12, desktop: 12, widescreen: 12},
  m:   {mobile: 12, tablet: 16, desktop: 16, widescreen: 16},
  l:   {mobile: 18, tablet: 24, desktop: 24, widescreen: 24},
  xl:  {mobile: 24, tablet: 40, desktop: 40, widescreen: 40},
  xxl: {mobile: 30, tablet: 64, desktop: 64, widescreen: 64},
}

export const radius = {
  xs: 2,
  s:  4,
  m:  6,
  l:  10,
}

export const barHeight = {
  xs: 32,
  s:  40,
  m:  48,
  l:  56,
  xl: 64,
}

export const columnWidth = 420
export const contentWidth = 600

export const icon = {
  xxs: {width: 8, height: 8},
  xs:  {width: 10, height: 10},
  s:   {width: 12, height: 12},
  m:   {width: 16, height: 16},
  l:   {width: 20, height: 20},
  xl:  {width: 32, height: 32},
  xxl: {width: 40, height: 40},
}

export const z = {
  body:        10,
  scrollToTop: 20,
  bodyTop:     30,

  header:       100,

  modal:        200,
  dragDrop:     250,
  lightbox:     260,
  inAppBrowser: 270,

  toast: 300,
  popup: 400,
  notification: 500,
}

//------
// Flexbox

export const flex = {
  column: {
    display:       'flex',
    flexDirection: 'column',
    alignItems:    'stretch',
  },
  row: {
    display:       'flex',
    flexDirection: 'row',
    alignItems:    'stretch',
  },
  center: {
    display:        'flex',
    flexDirection:  'column',
    alignItems:     'center',
    justifyContent: 'center',
  },
}

export function column(gap: number, alignItems: string = 'stretch') {
  return {
    ...flex.column,
    alignItems,

    '& > :not(:last-child)': {
      marginBottom: gap,
    },
  }
}

export function row(gap: number, alignItems: string = 'center') {
  return {
    ...flex.row,
    alignItems,

    '& > :not(:last-child)': {
      marginRight: gap,
    },
  }
}

export const overlay: React.CSSProperties = {
  position: 'absolute',
  top:      0,
  bottom:   0,
  left:     0,
  right:    0,
}

//------
// Responsive

export interface BreakpointConfig {
  minWidth?:  number | null
  maxWidth?:  number | null
  minHeight?: number | null
  maxHeight?: number | null
}

export function breakpoint(config: BreakpointConfig) {
  const spec = breakpointSpec(config)
  if (spec === '()') {
    return (styles: AnyObject) => styles
  } else {
    return (styles: AnyObject) => ({
      [`@media screen and ${breakpointSpec(config)}`]: styles,
    })
  }
}

export function breakpointSpec(config: BreakpointConfig) {
  const parts: string[] = []

  if (config.minWidth != null && config.minWidth > 0) {
    parts.push(`min-width: ${config.minWidth}px`)
  }
  if (config.maxWidth != null) {
    parts.push(`max-width: ${config.maxWidth}px`)
  }
  if (config.minHeight != null && config.minHeight > 0) {
    parts.push(`min-height: ${config.minHeight}px`)
  }
  if (config.maxHeight != null) {
    parts.push(`max-height: ${config.maxHeight}px`)
  }

  return `(${parts.join(' and ')})`
}

//------
// Specific sizes

export type ResponsiveSize = 'mobile' | 'tablet' | 'desktop' | 'widescreen'
export type ResponsiveProp<T> = {[key in ResponsiveSize]?: T} & {mobile: T}

export const screenWidths: Required<ResponsiveProp<number>> = {
  mobile:     320,
  tablet:     768,
  desktop:    1024,
  widescreen: 1216,
}

export const screenMinWidths: Required<ResponsiveProp<number>> = {
  mobile:     0,
  tablet:     768,
  desktop:    1024,
  widescreen: 1216,
}

export const mobile     = breakpoint({minWidth: screenMinWidths.mobile})
export const tablet     = breakpoint({minWidth: screenMinWidths.tablet})
export const desktop    = breakpoint({minWidth: screenMinWidths.desktop})
export const widescreen = breakpoint({minWidth: screenMinWidths.widescreen})

function responsive(config: ResponsiveProp<any> | ((size: ResponsiveSize) => AnyObject)) {
  let style: AnyObject = {}

  if (isFunction(config)) {
    const mapper = config as (size: ResponsiveSize) => AnyObject
    for (const size of Object.keys(screenMinWidths)) {
      style = {
        ...style,
        ...breakpoint({minWidth: screenMinWidths[size]})(mapper(size)),
      }
    }
  } else {
    for (const [size, value] of Object.entries(config)) {
      if (value == null) { continue }
      style = {
        ...style,
        ...breakpoint({minWidth: screenMinWidths[size]})(value),
      }
    }
  }

  return style
}
export {responsive}

export function responsiveProp(values: {[prop: string]: ResponsiveProp<any>}) {
  const config: ResponsiveProp<{[prop: string]: any}> = {} as any

  for (const [prop, propValues] of Object.entries(values)) {
    for (const [size, value] of Object.entries(propValues)) {
      if (value == null) { continue }

      if (config[size] == null) {
        config[size] = {}
      }
      config[size]![prop] = value
    }
  }

  return responsive(config)
}