import * as H from 'history'

export interface History extends H.History {
  stack: HistoryEntry[]
  index: number

  pushFrom(index: number, path: string): void
  goBack(options?: GoBackOptions): void
  reset(path?: string): void

  startTrace(): void
  stopTrace(): void

  save(): void
}

interface HistoryEntry {
  title:     string | null
  pathname:  string
}

export interface GoBackOptions {
  fallbackPath?: string
}

export function augmentHistory(base: H.History): History {
  const history = base as History

  const raw = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('history') : null
  const savedHistory = raw != null ? JSON.parse(raw) : null

  if (savedHistory != null && savedHistory.stack.find((it: HistoryEntry) => it.pathname === window?.location.pathname)) {
    history.stack = savedHistory.stack
    history.index = savedHistory.index
  } else {
    history.stack = [{
      title:    null,
      pathname: history.location.pathname,
    }]
    history.index = 0
  }

  history.pushFrom = (index, path) => {
    history.stack = history.stack.slice(0, index)
    history.push(path)
  }

  const origGoBack = history.goBack

  history.goBack = (options = {}) => {
    if (history.index > 0) {
      origGoBack.call(history)
    } else if (options.fallbackPath != null) {
      history.replace(options.fallbackPath)
    }
  }

  history.reset = (path?: string) => {
    history.index = 0
    history.stack = []

    if (path == null) {
      history.save()
      return
    }
    history.push(path)
  }

  history.listen((location, action) => {
    let stack           = history.stack.map(it => ({...it}))
    let index           = history.index
    const lastEntry     = stack[index]
    const existingEntry = stack.find(it => it.pathname === location.pathname)
    if (existingEntry != null) {
      index = stack.indexOf(existingEntry)
    } else if (action === 'REPLACE' && lastEntry != null) {
      lastEntry.pathname = location.pathname
    } else if (action === 'PUSH') {
      stack.splice(index + 1)
      stack.push({
        title:    null,
        pathname: location.pathname,
      })
      index = stack.length -1
    } else if (action === 'POP') {
      stack = [{
        title:    null,
        pathname: document.location.hash != null ? `${location.pathname}${document.location.hash}` : location.pathname,
      }]
      index = 0
    }

    history.stack = stack
    history.index = index

    history.save()
  })

  history.save = () => {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem('history', JSON.stringify({
        stack: history.stack,
        index: history.index,
      }))
    }
  }

  history.save()
  return history
}