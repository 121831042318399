import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { bewizrStore, projectStore } from '~/stores'
import DrawerToggle from '~/ui/app/drawer/DrawerToggle'
import { observer } from '~/ui/component'
import { BrandedComponent, BrandedLogo, HBox, PushButton, Tappable } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import AuthStatus from './AuthStatus'
import LanguageSwitcher from './LanguageSwitcher'

const AppHeader = observer('AppHeader', () => {

  const [t] = useTranslation('app')

  const {project, tabs} = projectStore
  const isDesktop = useMediaQuery({minWidth: screenMinWidths.desktop})

  const drawerEnabled = project?.feature('drawer')?.enabled && !isDesktop

  //------
  // Rendering

  const $ = useStyles()
  const {guide} = useStyling()

  function render() {
    return (
      <BrandedComponent branding={guide.web.header} height={height} classNames={$.appHeader}>
        <HBox flex gap={layout.padding.l} justify='space-between'>
          {renderLeft()}
          {renderRight()}
        </HBox>
      </BrandedComponent>
    )
  }

  function renderLeft() {
    return (
      <HBox gap={layout.padding.m}>
        {drawerEnabled && <DrawerToggle/>}
        <Tappable href={tabs[0]?.link.href ?? '/'}>
          <BrandedLogo/>
        </Tappable>
      </HBox>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.s}>
        {renderDashboardButton()}
        <LanguageSwitcher/>
        {!drawerEnabled && <AuthStatus/>}
      </HBox>
    )
  }

  function renderDashboardButton() {
    if (!bewizrStore.profile?.hasDashboardAccess) { return null }

    return (
      <PushButton
        icon='column-chart'
        caption={t('app:nav.dashboard')}
        href='https://be.wizr.eu/dashboard/'
      />
    )
  }

  return render()

})

export default AppHeader

export const height = layout.barHeight.l

const useStyles = createUseStyles({
  appHeader: {
    height:           height,
    position:         'relative',
    zIndex:           layout.z.header,
    borderTopWidth:   0,
    borderLeftWidth:  0,
    borderRightWidth: 0,

    ...layout.breakpoint({maxWidth: screenMinWidths.tablet - 1})({
      boxShadow: 'none',
    }),

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
})