import { CustomImage } from './CustomImage'
import { Model } from './Model'
import { Ref } from './types'

export class Channel extends Model {

  public type!: ChannelType

  public name!:  string
  public image!: CustomImage | null

  public pinIndex!: number | null
  public pinning!:  number | string

  public open?:  boolean
  public group!: Ref<any>

  public moderation!: boolean
  public moderator!: boolean | null

  public get isOpen() {
    return this.open
  }

  public get isModerated() {
    return this.moderation
  }

  public get isModerator() {
    return this.moderator
  }

}

export type ChannelType = 'participant' | 'group'
