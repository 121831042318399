import { action, when } from 'mobx'
import { Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrMeeting } from '~/models'
import bewizrStore from '../bewizrStore'

export default class BeWizrMeetingDocument extends Document<BeWizrMeeting> {

  @action
  public async enroll(occurrenceID: string) {
    const api = bewizrAPI()
    if (api == null) { return }

    const occurrence = this.data?.occurrences.find(it => it.id === occurrenceID)
    if (occurrence == null) { return }

    const response = await api.post(`meeting/${this.id}/enroll/${occurrenceID}/`)
    if (response.status === 200) {
      this.updateEnrollment(occurrenceID, true)
    }

    return response
  }

  @action
  public async cancel(occurrenceID: string) {
    const api = bewizrAPI()
    if (api == null) { return }

    const occurrence = this.data?.occurrences.find(it => it.id === occurrenceID)
    if (occurrence == null) { return }

    const response = await api.post(`meeting/${this.id}/cancel/${occurrenceID}/`)
    if (response.status === 200) {
      this.updateEnrollment(occurrenceID, false)
    }

    return response
  }

  @action
  protected async performFetch() {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get(`meeting/${this.id}`)
    if (response.status !== 200) { return response }

    const data = BeWizrMeeting.deserialize(response.data)

    return {
      data: data,
      meta: {},
    }
  }

  @action
  private updateEnrollment(occurrenceID: String, enrolled: boolean) {
    const occurrence = this.data?.occurrences.find(it => it.id === occurrenceID)
    if (occurrence == null) { return }

    const update = BeWizrMeeting.deserialize({
      ...this.data!.raw,
      occurrences: this.data!.raw.occurrences.map((it: any) => {
        if (it.id === Number(occurrenceID)) {
          return {...it, isEnrolled: enrolled}
        } else {
          return it
        }
      }),
    })
    this.set(update)
  }

  public resolveOccurrence(occurrenceID: string) {
    return this.data?.occurrences.find(it => it.id === occurrenceID)
  }
}