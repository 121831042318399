import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { BeWizrLibraryItem } from '~/models'
import { bewizrStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, List, SearchField, VBox } from '~/ui/components'
import { useQueryParam } from '~/ui/hooks'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import BeWizrCourseTile from './courses/BeWizrCourseTile'
import BeWizrLearningTrackTile from './learning-tracks/BeWizrLearningTrackTile'
import BeWizrMeetingTile from './meetings/BeWizrMeetingTile'

export interface BeWizrLibraryCategoryParams {
  slug: string
}

export type Props = RouteComponentProps<BeWizrLibraryCategoryParams>

const BeWizrLibraryCategoryScreen = observer('BeWizrLibraryCategoryScreen', (props: Props) => {

  const {slug} = props.match.params

  const [t] = useTranslation('bewizr')

  const document = bewizrStore.categories.document(slug)
  const category = document.data

  const endpoint = document.itemsEndpoint

  const fetchStatus = endpoint == null ? document.fetchStatus : endpoint.fetchStatus

  const fetchMore = React.useCallback(() => {
    endpoint?.fetchMore()
  }, [endpoint])

  const fetch = React.useCallback(() => {
    if (endpoint != null) {
      endpoint?.fetch()
    } else {
      document.fetch()
    }
  }, [document, endpoint])

  const [query, setQuery] = useQueryParam('query')

  React.useEffect(() => {
    if (endpoint == null) { return }

    if (query == null) {
      endpoint?.resetAndFetch()
    } else {
      endpoint?.fetchWithParams({query})
    }
  }, [query, endpoint])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={category?.title ?? null}
          fetchStatus={document.fetchStatus}
        />
        <VBox flex>
          {renderHeader()}
          <List
            data={endpoint?.data ?? []}
            onEndReached={fetchMore}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            itemGap={layout.padding.s}
            contentPadding={layout.padding.m}
            scrollable
          />
        </VBox>
      </>
    )
  }

  const renderEmpty = React.useCallback(() => (
    <EmptyOrFetching
      {...t(`library.empty.${query == null ? 'without_query' : 'with_query'}`)}
      status={fetchStatus}
      flex
    />
  ), [t, query, fetchStatus])

  function renderHeader() {
    return (
      <VBox padding={layout.padding.m}>
        <SearchField
          autoFocus
          initialSearch={query}
          onSearch={setQuery}
          debounce={200}

        />
      </VBox>
    )
  }

  function renderItem(item: BeWizrLibraryItem) {
    if (item.type === 'course') {
      return (
        <BeWizrCourseTile
          title={item.title}
          slug={item.slug}
          image={item.links.image}
          progress={false}
          horizontal
        />
      )
    } else if (item.type === 'learning_track') {
      return (
        <BeWizrLearningTrackTile
          title={item.title}
          image={item.links.image}
          id={item.id}
          horizontal
        />
      )
    } else if (item.type === 'meeting') {
      return (
        <BeWizrMeetingTile
          meetingID={item.id}
          title={item.title}
          image={item.links.image}
          horizontal
        />
      )
    } else {
      console.warn(`Learning activity "${item.type}" not supported`)
      return null
    }
  }

  return render()

})

export default BeWizrLibraryCategoryScreen