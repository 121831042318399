import { CollectionFetchOptions, Database, Document, Endpoint } from 'mobx-document'
import ChatService from './ChatService'
import { ChatDescriptor } from './types'

export default class PrivateChatsEndpoint extends Endpoint<PrivateChatDocument, {}, PrivateChatsMeta> {

  constructor(
    database: Database<PrivateChatDocument>,
    public readonly service: ChatService,
  ) {
    super(database, {}, {})
  }

  public async performFetch(params: {}, options: CollectionFetchOptions) {
    const response = await this.service.socket.fetch('private:fetch', params)
    if (!response.ok) { return response }

    const {chats, ...rest} = response.body
    const data = options.append ? [...this.data, ...chats] : chats as ChatDescriptor[]
    const meta = rest

    return {data, meta}
  }

  public async fetchMore() {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextPageToken == null) { return }

    await this.fetch({
      extraParams: {
        pageToken: this.meta.nextPageToken,
      },
      append: true,
    })
  }

}

export class PrivateChatDocument extends Document<ChatDescriptor> {

  protected async performFetch() {
    return null
  }
}

export interface PrivateChatsMeta {
  nextPageToken: string | null
}