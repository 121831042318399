import React from 'react'
import { CustomImage } from '~/models'
import { memo } from '~/ui/component'
import { Center, HBox, Label, LinkLabel, Panel, PushButton, Tappable, VBox } from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { ImageView } from '../media'

export interface Props {
  title:          string
  href:           string
  buttonCaption?: string
  image:          string | CustomImage | null
  subtitle?:      React.ReactNode
  icon?:          SVGName
  detail?:        React.ReactNode
  children?:      React.ReactNode
  horizontal?:    boolean
  locked?:        boolean
}

const BeWizrTile = memo('BeWizrTile', (props: Props) => {

  const {title, href, buttonCaption, subtitle, detail, icon, image, children, locked, horizontal} = props

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={[$.BeWizrTile, {horizontal}]} backgroundColor={colors.bg.alt} flex='grow'>
        {horizontal ? (
          <HBox align='stretch'>
            {renderContent()}
          </HBox>
        ) : (
          <VBox flex='grow'>
            {renderContent()}
          </VBox>
        )}
      </Panel>
    )
  }

  function renderContent() {
    return (
      <>
        {renderImage()}
        {renderBody()}
      </>
    )
  }

  function renderImage() {
    return (
      <VBox classNames={$.image} flex={horizontal}>
        <Tappable flex href={href} enabled={!locked}>
        {renderDetail()}
        {renderLocked()}
        {image != null && (
          <ImageView
            source={image}
            objectFit='cover'
            flex
          />
        )}
        </Tappable>
      </VBox>
    )
  }

  function renderDetail() {
    if (detail == null && icon == null) { return null }
    return (
      <VBox classNames={$.detail} align='left'>
        {renderIcon()}
        {detail}
      </VBox>
    )
  }

  function renderLocked() {
    if (!locked) { return null }

    return (
      <Center classNames={$.locked}>
        <ThemeProvider dark>
          <SVG
            name='lock'
            size={layout.icon.xxl}
            dim
          />
        </ThemeProvider>
      </Center>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }
    return (
      <Center classNames={[$.icon, {locked}]}>
        <SVG
          name={icon}
          size={layout.icon.s}
          dim={locked}
        />
      </Center>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.s} padding={layout.padding.inline.l} flex={horizontal ? true : 'grow'}>
        <VBox gap={layout.padding.inline.s} flex={!horizontal && 'grow'}>
          {renderTitle()}
          {renderSubtitle()}
        </VBox>
        <VBox>
          {children}
        </VBox>
        {renderButtons()}
      </VBox>
    )
  }

  function renderTitle() {
    const labelProps = {
      truncate: false,
      children: title,
      h3:       true,
    }
    if (locked) {
      return <Label dim {...labelProps}/>
    } else {
      return (
        <LinkLabel
          href={href}
          color={colors.fg.normal}
          {...labelProps}
        />
      )
    }
  }

  function renderSubtitle() {
    if (subtitle == null) { return null }

    return typeof subtitle === 'string' ? (
      <Label dim small>
        {subtitle}
      </Label>
    ) : subtitle
  }

  function renderButtons() {
    if (buttonCaption == null) { return null }
    return (
      <VBox flex={horizontal && 'grow'} justify='bottom' align={horizontal ? 'left' : 'stretch'}>
        <PushButton
          href={href}
          caption={buttonCaption}
          icon={locked ? 'lock' : undefined}
          enabled={!locked}
        />
      </VBox>
    )
  }

  return render()

})

export default BeWizrTile

const useStyles = createUseStyles(theme => ({
  BeWizrTile: {
    borderRadius: layout.radius.l,
    overflow:     'hidden',
    position:     'relative',

    '&.horizontal': {
      '& $image': {
        maxWidth: 180,
      },
    },
  },

  image: {
    position: 'relative',
    backgroundColor: theme.fg.dimmer,
    aspectRatio: 3 / 2,
  },

  icon: {
    padding: layout.padding.inline.s,
    borderRadius: '50%',
    backgroundColor: theme.colors.named.white,
    '&.locked': {
      backgroundColor: theme.colors.named.white.alpha(0.8),
    },
  },

  detail: {
    position: 'absolute',
    top:   layout.padding.inline.m,
    left:  layout.padding.inline.m,
    right: layout.padding.inline.m,
    zIndex: 20,
  },

  locked: {
    ...layout.overlay,
    backgroundColor: colors.shim.dark,
  },
}))