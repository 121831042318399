import React from 'react'
import { useTranslation } from 'react-i18next'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { BeWizrMeeting, BeWizrMeetingOccurrence, RemoteImage } from '~/models'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Empty,
  HBox,
  Hero,
  Label,
  Markdown,
  Notice,
  Panel,
  SVG,
  TextBlock,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import BeWizrSection from '../BeWizrSection'
import BeWizrMeetingEnrollmentForm from './BeWizrMeetingEnrollmentForm'
import BeWizrMeetingOccurrenceDateTimeView from './BeWizrMeetingOccurrenceDateTimeView'

export interface Props {
  meeting:    BeWizrMeeting
  occurrence?: BeWizrMeetingOccurrence
}

const BeWizrMeetingView = observer('BeWizrMeetingView', (props: Props) => {

  const [t] = useTranslation('bewizr')

  const {meeting, occurrence} = props

  const mayEnroll = React.useMemo(
    () => occurrence != null ? occurrence.mayEnroll : meeting.occurrences.some(it => it.mayEnroll),
    [occurrence, meeting.occurrences],
  )

  const occurrenceWithEnrollment = React.useMemo(() => {
    if (occurrence == null) {
      return meeting.occurrences.find(occurrence => occurrence.isEnrolled) ?? null
    } else {
      return occurrence.isEnrolled ? occurrence : null
    }
  }, [occurrence, meeting.occurrences])

  const description = React.useMemo(() => {
    if (meeting.descriptionHtml == null || meeting.descriptionHtml.length === 0) { return null }
    // A little bit ridiculous, but this way we can use the styling from the <Markdown/> component
    return NodeHtmlMarkdown.translate(meeting.descriptionHtml)
  }, [meeting.descriptionHtml])


  const locationDescription = React.useMemo(() => {
    if (occurrence?.location == null || occurrence.location.descriptionHtml.length === 0) { return null }
    // A little bit ridiculous, but this way we can use the styling from the <Markdown/> component
    return NodeHtmlMarkdown.translate(occurrence.location.descriptionHtml)
  }, [occurrence])

  const image: RemoteImage | undefined = meeting.links.image == null ? undefined : {
    type: 'remote',
    url:   meeting.links.image,
  }

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox>
        <Hero
          title={renderSummary()}
          image={image}
          backgroundImage={image}
        >
        </Hero>
        <VBox align='center' padding={layout.padding.m}>
          {renderContent()}
        </VBox>
      </VBox>
    )
  }

  function renderSummary() {
    if (occurrence == null && meeting.occurrences.length === 0) { return meeting.title }

    return (
      <VBox gap={layout.padding.l}>
        <VBox gap={layout.padding.inline.m}>
          <Label h1 truncate={false}>{meeting.title}</Label>
          {occurrenceWithEnrollment != null && renderStatus()}
        </VBox>
        <VBox gap={layout.padding.inline.m}>
          {occurrence == null && occurrenceWithEnrollment == null && (
            <Label caption small classNames={$.next}>
              {t('meetings.next')}
            </Label>
          )}
          {renderDate()}
          {renderTime()}
          {renderFacilitator()}
          {renderOnline()}
          {renderLocation()}
        </VBox>
      </VBox>
    )
  }

  function renderStatus() {
    if (occurrenceWithEnrollment == null) { return null }

    return (
      <Notice backgroundColor={colors.named.lightGreen} padding={layout.padding.inline.m} classNames={$.status}>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='check' size={layout.icon.m}/>
          <Label truncate={false} flex tiny>
            {t('meetings.enrollment.status.enrolled.full')}
          </Label>
        </HBox>
      </Notice>
    )
  }

  function renderDate() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='calendar' size={layout.icon.m}/>
        <BeWizrMeetingOccurrenceDateTimeView
          occurrence={occurrence ?? occurrenceWithEnrollment ?? meeting.occurrences[0]}
          dateFormat='d LLLL yyyy'
          timeFormat={false}
        />
      </HBox>
    )
  }

  function renderTime() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='clock' size={layout.icon.m}/>
        <BeWizrMeetingOccurrenceDateTimeView
          occurrence={occurrence ?? occurrenceWithEnrollment ?? meeting.occurrences[0]}
          dateFormat={false}
        />
      </HBox>
    )
  }

  function renderOnline() {
    const {isOnline} = occurrence ?? occurrenceWithEnrollment ?? meeting.occurrences[0]
    if (!isOnline) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='webcast' size={layout.icon.m}/>
        <Label>
          {t('meetings.online')}
        </Label>
      </HBox>
    )
  }

  function renderFacilitator() {
    const {facilitator} = occurrence ?? occurrenceWithEnrollment ?? meeting.occurrences[0]
    if (facilitator == null) { return null}

    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='trainer' size={layout.icon.m}/>
        <Label>
          {facilitator.fullName}
        </Label>
      </HBox>
    )
  }

  function renderLocation() {
    const {location} = occurrence ?? occurrenceWithEnrollment ?? meeting.occurrences[0]
    if (location == null) { return null}

    return (
      <VBox gap={layout.padding.inline.s}>
        <HBox gap={layout.padding.inline.m}>
          <SVG name='map-pin' size={layout.icon.m}/>
          <Label>{location.title}</Label>
        </HBox>
        <VBox>
          <TextBlock classNames={$.address}>
            {[location.addressLine, `${location.addressZip} ${location.addressCity}`].join('\n')}
          </TextBlock>
        </VBox>
      </VBox>
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content} gap={layout.padding.xl} paddingVertical={layout.padding.l}>
        <BeWizrSection title={t('meetings.description')}>
          {renderDescription()}
        </BeWizrSection>
        {locationDescription != null && (
          <BeWizrSection title={t('meetings.location.title')}>
            {renderLocationDescription()}
          </BeWizrSection>
        )}
        {!(occurrence != null && occurrence.isEnrolled && !occurrence.mayEnroll) && (
          <BeWizrSection title={t('meetings.enrollment.title')}>
            {renderEnroll()}
          </BeWizrSection>
        )}
      </VBox>
    )
  }

  function renderDescription() {
    if (description == null) { return null }

    return (
      <Markdown>
        {description}
      </Markdown>
    )
  }

  function renderLocationDescription() {
    if (occurrence?.location == null || locationDescription == null) { return null }
    const {location} = occurrence

    const href = `https://maps.google.com/?q=${encodeURIComponent([location.addressLine, location.addressZip, location.addressCity, location.addressCountry].join(', '))}`
    return (
      <VBox gap={layout.padding.l}>
        <Markdown>
          {locationDescription}
        </Markdown>
        <Panel padding={layout.padding.m} gap={layout.padding.m}>
          <Label h3>
            {location.title}
          </Label>
          <TextBlock>
            {[location.addressLine, `${location.addressZip} ${location.addressCity}`, location.addressCountry].join('\n')}
          </TextBlock>
          <HBox>
            <ClearButton
              href={href}
              icon='map'
              caption={t('meetings.location.show_on_map')}
            />
          </HBox>
        </Panel>
      </VBox>
    )
  }

  function renderEnroll() {
    if (meeting == null) { return null }

    return mayEnroll ? (
      <BeWizrMeetingEnrollmentForm
        meeting={meeting}
        occurrence={occurrence}
      />
    ) : (
      <Panel>
        <Empty
          icon='info'
          title={t('meetings.enrollment.status.closed.full')}
          small
        />
      </Panel>
    )
  }

  return render()

})

export default BeWizrMeetingView

const useStyles = createUseStyles({
  content: {
    width:    layout.contentWidth,
    maxWidth: '100%',
  },

  status: {
    alignSelf: 'flex-start',
  },

  address: {
    marginLeft: layout.icon.m.width + layout.padding.inline.m,
  },

  next: {
    marginBottom: layout.padding.inline.m,
  },
})