import Toast from 'react-toast'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import I18n from 'i18next'
import { authenticationStore } from '~/stores'

export function createAxios(config: AxiosConfig) {
  const axios = Axios.create({
    baseURL: config.baseURL,
    // Never throw errors.
    validateStatus: null,
  })

  if (config.authToken != null) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${config.authToken}`
  }

  axios.interceptors.response.use(response => {
    const {status} = response
    if (status < 400) { return response }
    if (status >= 500) {
      Toast.show({
        type:   'error',
        ...I18n.t<any>(['errors:500', 'errors:unknown']),
      })
    } else if (status === 401) {
      authenticationStore.logOut()
    } else if (status !== 404 && status !== 410 && status !== 422) {
      Toast.show({
        type:   'error',
        ...I18n.t<any>([`errors:${status}`, 'errors:unknown']),
      })
    }

    return {
      ...response,
      error: AxiosError.from(response),
    }
  }, error => {
    // As we're validating any response status, the only reason an error will be thrown will be a
    // connection issue.
    Toast.show({
      type:   'error',
      ...I18n.t<any>('errors:no_connection'),
    })

    // I don't like exceptions on runtime stuff. Rather, I'd like to return a response with a 0 status and
    // `null` data.
    const response: AxiosResponse = error.response ?? {
      data:       null,
      status:     0,
      statusText: "No Connection",
      headers:    {},
      config:     error.request?.config ?? {},
      request:    error.request,
    }

    return response
  })

  return axios
}

export interface AxiosConfig {
  baseURL:   string
  authToken: string | null
}