import { Database } from 'mobx-document'
import { BeWizrUserDocument } from './bewizr-users'
import { register } from './support'

export class BeWizrUsersStore {

  public readonly users = new Database<BeWizrUserDocument>({
    getID:         user => user.id,
    getDocument:   user => new BeWizrUserDocument(user.id, {initialData: user}),
    emptyDocument: id   => new BeWizrUserDocument(id),
  })

}

export default register(new BeWizrUsersStore())