import { DateTime } from 'luxon'
import { makeAutoObservable, runInAction } from 'mobx'
import { bewizrAPI, initBeWizrAPI } from '~/apis/bewizr'
import config from '~/config'
import { Database } from '~/lib/mobx-document'
import { BeWizrProfile } from '~/models'
import { HrefResolver } from '~/navigation'
import authenticationStore from './authenticationStore'
import {
  BeWizrCourseDocument,
  BeWizrCourseItemDocument,
  BeWizrCourseItemsEndpoint,
  BeWizrCoursesEndpoint,
  BeWizrLearningTrackDocument,
  BeWizrLearningTracksEndpoint,
  BeWizrLibraryTopicDocument,
  BeWizrLibraryTopicsEndpoint,
  BeWizrMeetingDocument,
} from './bewizr'
import BeWizrLibraryCategoryDocument from './bewizr/BeWizrLibraryCategoryDocument'
import profileStore from './profileStore'
import searchStore from './searchStore'
import { register } from './support'

export class BeWizrStore {

  constructor() {
    makeAutoObservable(this)
  }

  public authenticated: boolean = false

  public profile: BeWizrProfile | null = null

  public readonly courses = new Database<BeWizrCourseDocument>({
    getID:         course => course.slug,
    getDocument:   course => new BeWizrCourseDocument(course.slug, {initialData: course}),
    emptyDocument: slug   => new BeWizrCourseDocument(slug),
  })

  public readonly courseItems = new Database<BeWizrCourseItemDocument>({
    getID:         item => item.id,
    getDocument:   item => new BeWizrCourseItemDocument(item.id, {initialData: item}),
    emptyDocument: id   => new BeWizrCourseItemDocument(id),
  })

  public readonly learningTracks = new Database<BeWizrLearningTrackDocument>({
    getID:         track => track.id,
    getDocument:   track => new BeWizrLearningTrackDocument(track.id, {initialData: track}),
    emptyDocument: id    => new BeWizrLearningTrackDocument(id),
  })

  public readonly meetings = new Database<BeWizrMeetingDocument>({
    getID:         meeting => meeting.id,
    getDocument:   meeting => new BeWizrMeetingDocument(meeting.id, {initialData: meeting}),
    emptyDocument: id      => new BeWizrMeetingDocument(id),
  })

  public readonly topics = new Database<BeWizrLibraryTopicDocument>({
    getID:         theme => theme.id,
    getDocument:   theme => new BeWizrLibraryTopicDocument(theme.id, {initialData: theme}),
    emptyDocument: id    => new BeWizrLibraryTopicDocument(id),
  })

  public readonly categories = new Database<BeWizrLibraryCategoryDocument>({
    getID:         category => category.slug,
    getDocument:   category => new BeWizrLibraryCategoryDocument(category.slug, {initialData: category}),
    emptyDocument: slug     => new BeWizrLibraryCategoryDocument(slug),
  })

  public readonly myCourses        = new BeWizrCoursesEndpoint(this.courses, {})
  public readonly myLearningTracks = new BeWizrLearningTracksEndpoint(this.learningTracks, {})
  public readonly myTopics         = new BeWizrLibraryTopicsEndpoint(this.topics, {})

  public resolveHref = (match: RegExpMatchArray): string => {
    const baseURL = `https://${config.environment === 'live' ? 'be' : 'acc-be'}.wizr.eu`
    const href    = `${baseURL}/${match[1]}`
    const tokens  = authenticationStore.getOAuthTokens('bewizr')
    if (tokens == null) {
      console.warn(`No access token available to resolve BeWizr href: "${href}"`)
      return href
    }

    const resolved = new URL(`${baseURL}/login_and_redirect`)
    resolved.searchParams.set('next', href)
    resolved.searchParams.set('access_token', tokens.access_token)
    resolved.searchParams.set('return_to', window.location.href)
    if (profileStore.participant != null) {
      resolved.searchParams.set('language', profileStore.participant?.language)
    }

    return resolved.href
  }

  public init() {
    authenticationStore.on('oauth:token-refreshed', this.onLogIn)
    authenticationStore.on('login', this.onLogIn)
    authenticationStore.on('logout', this.onLogOut)
    if (authenticationStore.loginStatus === 'logged-in') {
      this.onLogIn()
    }
  }

  private onLogIn = () => {
    const tokens = authenticationStore.getOAuthTokens('bewizr')
    if (tokens == null) { return }

    // If the current token is expired, don't initialize the API to prevent an authentication error
    // The authentication store will refresh the token, calling this event handler once again
    const now = DateTime.now()
    if (tokens.expires_at <= now.toSeconds()) { return }

    initBeWizrAPI(tokens.access_token)

    searchStore.registerEndpoint('bewizr-courses', new BeWizrCoursesEndpoint(this.courses, {}))
    searchStore.registerEndpoint('bewizr-course-items', new BeWizrCourseItemsEndpoint(this.courseItems, {}))

    HrefResolver.register(
      /^https:\/\/(?:acc-be|be).wizr.eu\/(.*)/i,
      this.resolveHref,
    )

    this.fetchProfile()

    this.authenticated = true
  }

  public fetchProfile = async () => {
    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('profile/')
    if (response.status !== 200) { return response }

    runInAction(() => {
      this.profile = BeWizrProfile.deserialize(response.data)
    })

  }

  public switchLanguage = async (language: string) => {
    const api = bewizrAPI()
    if (api == null) { return true }

    const response = await api.post('set_language/', {language})
    if (response.status !== 200) { return response }

    return true
  }

  private onLogOut = () => {
    this.courses.clear()
    this.authenticated = false
  }
}

export default register(new BeWizrStore())