import React from 'react'
import { Helmet } from 'react-helmet'
import { appStore } from '~/stores'
import createUseGlobalStyles from '~/ui/app/globalStyles'
import { observer } from '~/ui/component'
import { Center, ErrorDisplay } from '~/ui/components'
import { BrandingGuide, createUseStyles, StylingProvider } from '~/ui/styling'

export interface Props {
  status:    number
  title:     string
  detail:    string
  children?: React.ReactNode
}

const ErrorPage = observer('ErrorPage', (props: Props) => {

  const {status, title, detail, children} = props

  //------
  // Branding

  const branding = appStore.app?.branding

  const brandingGuide = React.useMemo(() => {
    let guide: BrandingGuide
    if (branding != null) {
      guide = BrandingGuide.deserialize(branding)
    } else {
      guide = new BrandingGuide()
    }
    return guide
  }, [branding])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <GlobalStyles/>
        <StylingProvider guide={brandingGuide}>
          <Helmet
            title={`Error ${status} | ${title}`}
          />
          <Center classNames={$.ErrorPage} flex>
            <ErrorDisplay
              status={status}
              title={title}
              detail={detail}
              children={children}
            />
          </Center>
        </StylingProvider>
      </>
    )
  }

  return render()

})

const useGlobalStyles = createUseGlobalStyles({transparent: true})
export default ErrorPage

const GlobalStyles = () => {
  useGlobalStyles()
  return null
}

const useStyles = createUseStyles(theme => ({
  ErrorPage: {
    backgroundColor: theme.bg.normal,
  },
}))
