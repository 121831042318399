import { Database } from 'mobx-document'
import socket from 'socket.io-react'
import { Participant } from '~/models'
import authenticationStore from './authenticationStore'
import { ParticipantDocument } from './participants'
import ParticipantsEndpoint from './participants/ParticipantsEndpoint'
import searchStore from './searchStore'
import { register } from './support'

export class ParticipantsStore {

  public readonly participants = new Database<ParticipantDocument>({
    getDocument:   participant => new ParticipantDocument(participant.id, {initialData: participant}),
    emptyDocument: id => new ParticipantDocument(id),
    getID:         participant => participant.id,
  })

  public init() {
    authenticationStore.on('logout', this.onLogOut)
    searchStore.registerEndpoint('participants', new ParticipantsEndpoint(this.participants, {}))
  }

  public async findByEmail(email: string) {
    const response = await socket.fetch('participants:show', email)
    if (!response.ok) { return null }

    const participant = Participant.deserialize(response.body.participant)
    this.participants.store(participant)

    return participant
  }

  private onLogOut = () => {
    this.participants.clear()
  }

}

const participantsStore = register(new ParticipantsStore())
export default participantsStore