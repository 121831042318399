import React from 'react'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { Label, LabelProps } from './label'

export interface Props extends Omit<LabelProps, 'children'> {
  start:        DateTime
  end:          DateTime
  dateFormat?:  string | false
  timeFormat?:  string | false
}

const DateTimeRangeDisplay = memo('DateTimeRangeDisplay', (props: Props) => {

  const {start, end, dateFormat = 'd-M-yyyy', timeFormat = 'HH:mm', ...rest} = props

  //------
  // Rendering

  function render() {
    const parts: string[] = []
    if (dateFormat !== false) {
      parts.push(renderDate(start))
    }
    if (timeFormat !== false) {
      parts.push(renderTime(start))
    }
    if (!start.hasSame(end, 'day') && dateFormat !== false) {
      parts.push('-')
      parts.push(renderDate(end))
    } else if (timeFormat !== false) {
      parts.push('-')
    }
    if (timeFormat !== false) {
      parts.push(renderTime(end))
    }
    return (
      <Label truncate={false} convertNewlines={true} {...rest}>
        {parts.join(' ')}
      </Label>
    )
  }

  function renderDate(date: DateTime) {
    if (dateFormat === false) { return '' }
    return date.toFormat(dateFormat, {locale: i18next.language})
  }

  function renderTime(date: DateTime) {
    if (timeFormat === false) { return '' }
    return date.toFormat(timeFormat, {locale: i18next.language})
  }

  return render()

})

export default DateTimeRangeDisplay