import { makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import { BeWizrActivity } from '~/models'
import { BeWizrActivityDocument, BeWizrAgendaEndpoint } from './bewizr-agenda'
import { register } from './support'

export class BeWizrAgendaStore {

  constructor() {
    makeAutoObservable(this)
  }

  public readonly items = new Database<BeWizrActivityDocument>({
    getID:         item => item.id,
    getDocument:   item => new BeWizrActivityDocument(item.id, {initialData: item}),
    emptyDocument: id   => new BeWizrActivityDocument(id),
  })

  public readonly myActivities = new BeWizrAgendaEndpoint(this.items, {})

  public hrefForActivity(activity: BeWizrActivity) {
    const url = new URL(activity.links.self)
    const match = url.pathname.match(/^\/frontend\/api\/meeting\/(.*)\//)
    if (match == null) { return }

    const meetingID = match[1]
    return `//bewizr-meeting/${meetingID}/occurrences/${activity.id}`
  }
}

export default register(new BeWizrAgendaStore())